div.kazvabg-rating-widget__button-wrapper::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        #20e534 0%,
        #1ed931 10%,
        #53b7ff 30%,
        #538cff 50%,
        #c36eff 70%,
        #ff4d40 90%,
        #ff4133 100%
    );
    border-radius: 50px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 0;
    transition: opacity 0.8s ease, -webkit-filter 0.8s ease;
    transition: filter 0.8s ease, opacity 0.8s ease;
    transition: filter 0.8s ease, opacity 0.8s ease, -webkit-filter 0.8s ease;
}

div.kazvabg-rating-widget__button-wrapper:hover::before {
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 1;
}

div.kazvabg-rating-widget__button-container {
    width: 115px;
    height: 42px;
    position: relative;
}

div.kazvabg-rating-widget__gradient {
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
    transition: -webkit-filter 0.8s ease;
    transition: filter 0.8s ease;
    transition: filter 0.8s ease, -webkit-filter 0.8s ease;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}

div.kazvabg-rating-widget__button-wrapper:hover div.kazvabg-rating-widget__gradient {
    -webkit-filter: blur(5px);
            filter: blur(5px);
    opacity: 0.3;
}

div.kazvabg-rating-widget__button-wrapper:hover div.kazvabg-rating-widget__gradient::before {
    opacity: 0;
}

a.kazvabg-rating-widget__button {
    transition: box-shadow 0.25s ease;
}

a.kazvabg-rating-widget__button:active + div.kazvabg-rating-widget__gradient {
    -webkit-filter: blur(0);
            filter: blur(0);
}

a.kazvabg-rating-widget__button:active {
    box-shadow: 0px 2.839px 8.518px 0px rgba(0, 0, 0, 0.1),
        0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset !important;
}

div.kazvabg-rating-widget__gradient::before {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    background: conic-gradient(transparent 0deg,
            white 45deg,
            white 315deg,
            transparent 360deg);
    border-radius: 50%;
    z-index: 1000;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
    opacity: 1;
    transition: opacity 0.8s ease;
    -webkit-animation: kazvabg-rating-widget__gradient-animation 3s linear infinite;
            animation: kazvabg-rating-widget__gradient-animation 3s linear infinite;
    will-change: transform;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}

@-webkit-keyframes kazvabg-rating-widget__gradient-animation {
    from {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
                transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
                transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes kazvabg-rating-widget__gradient-animation {
    from {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
                transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
                transform: translate(-50%, -50%) rotate(360deg);
    }
}