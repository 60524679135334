@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=cyrillic-ext,greek-ext);
html { height: 100%; }
body {
    font-family: 'Roboto', sans-serif;
    background-color: #ebebeb;
    height: 100%;
}

h5 { text-transform: uppercase; font-size: 1.6rem; }
.subtitle { font-size: 1.4rem; color: #c0c0c0; display: block; margin-top: 8px; }

.flex { display: -webkit-flex; display: flex; -webkit-flex-flow: row; flex-flow: row }

/* TYPOGRAPHY */

label svg { vertical-align: text-bottom; margin-left: 4px; margin-right: 4px; }

.text-label { text-transform: uppercase; }
.text-label svg { vertical-align: text-top }

.text-light { font-weight: 300; }
.text-regular { font-weight: 400; }
.text-medium { font-weight: 500; }
.text-bold { font-weight: 700; }
.text-black { font-weight: 900; }

.text-white { color: #fff; }
.text-blue { color: #337ab7; }

.group-item .bg-muted {	background: #f1f1f1; }

.page-heading { 
	font-weight: 900; font-size: 3.6rem;
    text-transform: uppercase;
    margin: 0 12px 16px; line-height: 60px; min-height: 60px;
}

h5.page-heading { font-size: 2.4rem; }

.page-heading .btn-xs { 
    display: inline-block; vertical-align: middle; 
    margin-left: 8px; margin-top: -3px;
}

.page-heading .btn-toolbar { display: inline-block; line-height: 5.8rem; }
.page-heading .btn-toolbar .btn { vertical-align: middle; }

.page-heading:after {
    content: '';
    display: block;
    clear: both;
}

/* ------------ */

#root { height: 100%; }

.btn-support {
    background: #898989; color: #fff;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}

.alert svg { vertical-align: bottom; margin: 0 4px; }

.bg-dark {
    background-color: #666666;
	color: #ebebeb;
}

.sub-header {
    height: 5px;
    color: #606060;
    position: fixed;
    z-index: 999;
    left: 0; top: 0;
    padding: 0;
    width: 100%;
}

.notifications-wrapper {
    position: relative;
    z-index: 5;
}

.main { 
    left: 0;
    height: calc(100% - 36px); box-sizing: border-box;
    transition: left 0.1s ease-out;
    -webkit-align-items:flex-start;
            align-items:flex-start;
    -webkit-align-content:flex-start;
            align-content:flex-start; 
    overflow: hidden;
}

.main.open {
    left: 200px;
    transition: left 0.2s ease-in;
    overflow: visible;
}

.main .content {
    width: 100%;
    height: calc(100% - 70px); 
    overflow: auto;
    padding: 24px 16px 0; 
    position: relative;
}

.main .content.live-feed-epxanded { height: calc(100% - 120px); }

#menuToggle input {
  display: block;
  width: 40px; height: 32px;
  position: absolute; top: -7px; left: -5px;  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

.votes-timeline-container { 
    width: 320px; height: auto;
    margin: 54px 16px 0;
    background: #d7d7d7; 
}

.votes-timeline-container h3 { 
    padding-left: 16px; 
    margin-top: 0; margin-bottom: 0;
    text-transform: uppercase; line-height: 4.8rem; color: #fff; font-size: 1.8rem;
    background-color: #666;
} 

.votes-feed {
    padding-bottom: 54px;
    overflow: auto;
}

.votes-timeline-container .votes-timeline { margin-left: 24px; }

.overview {
    display: -webkit-flex;
    display: flex; -webkit-flex: 1 1; flex: 1 1;
    margin: 0; 
}

.overview .topics-tree-container { 
    -webkit-flex: 3 1; 
            flex: 3 1; padding: 0;
}

.radial-bar-mobile { display: none; }
.radial-bar-desktop { display: block; }

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.table th {
  text-align: center
}

.list-container .grid-container .grid-container-filters input{
    margin: 5px 18px 5px 0;
}

.list-container .grid-container .grid-container-filters button{
    margin: 0 4px;
}

.list-container .grid-container .grid-container-filters label{
    margin-right: 8px;
}

.list-container .grid-container .user-management-grid-container .search-field .form-control {
    background: none;
}

.list-container .grid-container .user-management-grid-container .react-bs-table {
    margin: 8px;
    border-radius: 5px;
}

.list-container .grid-container .user-management-grid-container .react-bs-container-header {
    display: none;
}

.react-bs-table .table > tbody > tr > td { vertical-align: middle; background: #fafafa; border-top: 4px solid #fff; outline: transparent }
.react-bs-table .table > tbody > tr:hover > td { background: #fff; }

.react-bs-table .table > tbody > tr > td.action-cell { padding: 0; text-overflow: unset; overflow: unset }
.react-bs-table .table > tbody > tr > td.action-cell:hover { background: #87cdfe; }

.react-bs-table .table > tbody > tr > td.action-cell .btn { color: #000 }
.react-bs-table .table > tbody > tr > td.action-cell:hover .btn { color: #fff }

.radial-bar{
    padding-top:50px;
    padding-left:30px;
}

.graph-container-row{
    margin-top:50px;
}

.btn-toolbar.no-float .btn {
    float: none;
}

button, .btn:focus { outline: none; }

.btn { text-transform: uppercase; padding: 4px 8px; font-weight: bold; position: relative; }

.btn.btn-xs { font-size: 11px; padding: 4px; }

.btn svg { vertical-align: text-bottom; margin-right: 4px; }

.btn-outline-danger, .btn-outline.btn-danger { border-color: #d15e42; color: #d15e42; }
.btn-outline-primary, .btn-outline.btn-primary { border-color: #3797c4; color: #3797c4; }
.btn-outline-success, .btn-outline.btn-success { border-color: #5cb85c; color: #5cb85c; }
.btn-outline-white, .btn-outline.btn-white { border-color: #fff; color: #fff; }

.btn-outline.btn-white:hover, .btn-white:hover { background-color: #fff; color: #999; }

.btn-outline {
    border: 1px solid #999; color: #999;
    background: transparent;
    border-radius: 4px;
    text-align: center;
    padding: 4px 8px;
}

.btn-outline:hover { color: #999; }

.btn-full-width { display: block; width: 100%; }

.btn-dotted { border-style: dotted; }

.btn-float {
    position: fixed; z-index: 4;
    right: 8px; bottom: 48px;
    border: none;
    text-align: center;
    white-space: nowrap;
    padding: 0;
}

.btn-float .btn {
    width: 48px; height: 48px;
    border-radius: 24px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
    padding: 0;
    text-align: center;
    line-height: 45px;
}
.btn-float.btn-float-second { bottom: 108px; }

.btn-float label {
    display: none;
    text-transform: uppercase;
    background: #fff;
    padding: 4px 8px;
    margin-right: 0;
}

.btn-float:hover label {
    display: inline-block;
}

.btn-float .btn svg { vertical-align: middle; margin: 0; }

.btn-group-footer {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-align-content: space-around;
            align-content: space-around;
    padding: 0;
    box-sizing: inherit;
}

.btn.transparent, .btn.btn-transparent, .btn-transparent { border: none; background: none; }

.dropdown-button-container {
    border: 1px solid #999; color: #999;
    background: transparent;
    border-radius: 4px;
}

.dropdown-button {
    width: 100%;
}

.dropdown-list-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.dropdown-list-item {
    border: 0;
}

.dropdown-list-item:hover {
    color: #333;
}

.message { padding: 8px; border-radius: 2px; }
.message svg { vertical-align: middle }

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.3);
	opacity: 0;
	z-index: 9998;
	visibility: hidden;
	transition: visibility 0s 0.3s, opacity 0.3s;
}

.overlay {
	opacity: 1;
	visibility: visible;
	transition-delay: 0s;
}

.leaflet-container {
    height: 400px;
    width: 100%;
    z-index: 1;
}
.leafet-top {
    z-index: 2;
}

.hidden-element { 
    opacity: 0; 
	width: 1px; 
	height: 1px;
	overflow: hidden;
}

.white-background-color { 
    background-color: #fff;
}

.transparent-background-color { 
    background-color: transparent;
}

.menu-mobile {display: none !important;}

.static-page h2,
.static-page h3 { margin-bottom: 32px; }

.static-page, .card.static-page {
    padding-left: 32px; padding-right: 32px;
    font-size: 1.8rem; 
}

.static-page table {
    width: 100%;
    table-layout: fixed;
    margin: 8px 0 16px;
}

.static-page table tr td,
.static-page table tr th {
    padding: 8px;
}

.static-page p { margin-top: 8px; margin-bottom: 16px }


.footer {
    position: fixed; left: 0; bottom: 0; z-index: 3; 
    width: 100%; height: 36px;
}

.footer-content { 
    display: block;
    position: relative;
    width: 100%; height: 100%;
    background: #cfcfcf;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
    font-size: 1.2rem; line-height: 40px;
    padding-left: 8px; padding-right: 8px;
}

.footer .logo { margin-top: 6px; margin-right: 16px; opacity: 0.1 }

.footer span, .footer a, .footer label { vertical-align: middle; font-weight: 300; }

.footer label { margin-right: 0; margin-top: 8px; }
.footer label svg { vertical-align: text-bottom; float: left; margin-right: 4px; }

.footer label small,
.footer label span { display: block; line-height: 1.0rem; white-space: nowrap }

.footer label a { position: relative; display: block; float: left; }
.footer label a .notification {
    padding: 0;
    background: #FF0000;
    color: #fff;
    position: absolute;
    height: 12px; width: 12px;
    top: -6px; right: 0; 
    text-align: center; font-weight: bold;
    line-height: normal;
    border-radius: 4px;
    font-size: 1.0rem;
}

/* ========= navbar ================= */
/* @import "App769.css"; */

@media (max-width: 769px) {
    .main { display: block; padding-top: 0; position: relative }
    .main .content { display: block; padding: 0 8px 0; height: 100%;  }
    
    .hide-on-mobile { display: none !important; }

	.sub-header { height: 10px; }
	
    .navbar-toggle { 
		font-size: 2.4rem; border: none; 
		margin: 5px 0 0; padding: 10px;
		float: none;
	}

    .navbar-fixed-side {
        width: auto; border: none;
        margin-bottom: 0;
	}
	
	.navbar-collapse.collapse {
		display: none !important;
		width: 100%;
        padding: 0;
        /*border-top: 1px solid #e7e7e7*/
    }
	
	.navbar > .container .navbar-brand {
		display: none
	}

	.nav > li > a { padding-left: 5px }
	.nav > li > a > * { display: inline-block; vertical-align: bottom; margin-right: 5px; }

    .container > .navbar-header { margin-left: 0; margin-right: 0; }	
    
    .page-heading { 
        font-size: 2.4rem; line-height: 2.8rem;
    }

    .overview { -webkit-flex: 1 1; flex: 1 1; margin: 0; }
    .overview .topics-tree-container { padding: 0; -webkit-flex: 1 1; flex: 1 1 }

    .votes-timeline-container { display: none; }

    .radial-bar-mobile { display: block; }
    .radial-bar-desktop { display: none; }

    .menu-mobile {
        display: block !important;
    }

    .navbar-mobile {
        height: 40px; 
        width: 100%;
        display: -webkit-flex;
        display: flex;
        box-sizing: border-box;
        -webkit-flex: 1 1 auto;
                flex: 1 1 auto;
        background: #e7e7e7eb;
        box-shadow: 0 0 50px #7773; 
        font-size: 1.5rem;
        padding: 0 20% 0 20%;
        bottom: 0;
        position: -webkit-sticky;
        position: sticky; z-index: 999;
    }

}

.col-xs-12 { padding-right: 4px; padding-left: 4px; }

/* ANIMATIONS */
.animate,
.animate svg {
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}

.animate.animate-close {
    height: 0; overflow: hidden;
}

.animate.animate-open {
    height: auto; overflow: auto;
}

@media (min-width: 769px) {
    .hide-on-desktop {
        display: none;
    }

    .navbar-fixed-side {
        position: fixed;
        height: 100vh;
        width: inherit;
        border: none;
        z-index: 10;
    }
  
    .navbar-fixed-side .container,
    .navbar-fixed-side .container-fluid {
        width: auto;
        padding-right: 0;
        height: 60px;
    }

    .navbar-fixed-side .navbar-header {
        float: none;
    }

    .navbar-fixed-side .navbar-collapse {
        width: 100%;
        padding: 0;
        /*border-top: 1px solid #e7e7e7*/
    }

    .navbar-fixed-side .navbar-nav{
      width: inherit;
    }
    
    .navbar-fixed-side .navbar-nav > li {
        float: none; padding-left: 33px;
        font-size: 18px;
    }

    .navbar-fixed-side .navbar-nav > li > a {
		display: block; float: left;
        font-size: 2.4rem; color: #999;
        white-space: nowrap;
        transition: all 0.3s ease 0.3s;        
    }

    .navbar-fixed-side .navbar-nav > li > a span {
        margin-left: 8px;
        max-width: 0; display: inline-block; 
        overflow: hidden;
        transition: all 0.3s ease 0.3s;
    }
    
    .navbar-fixed-side .navbar-nav > li > a.active{
        color: #000;
    }

    .navbar-fixed-side .navbar-nav > li > a:hover svg {
        color: #000;        
    }        

    .navbar-fixed-side .navbar-nav > li > a:hover {
        background-color: #fff;
        transition: all 0.3s ease 1.2s;        
    }

    .navbar-fixed-side .navbar-nav > li > a:hover span {
        max-width: 300%;
        transition: all 0.3s ease 1.2s;
    }

  /* On using dropdown menu (To right shift popuped) */
    .navbar-fixed-side .navbar-nav .dropdown-menu {
        position: absolute;
        left: 100%;
        z-index: 1000;
        float: none;
        width: auto;
        margin-top: 0;
        border: 0;
        box-shadow: none;
        border-bottom: 1px solid #333333;
        background-color: #222222;
    }

      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a,
      .navbar-fixed-side .navbar-nav .dropdown-menu .dropdown-header {
          padding: 5px 15px 5px 25px;
          z-index: 1000;
      }

      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a {
          line-height: 20px;
          color: #777
      }

      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a:hover,
      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a:focus {
        background-image: none
     }

     .navbar-fixed-side .navbar-nav .dropdown-menu>.active>a {
        /*background-color: #e7e7e7;*/
        color: #555
     }

     .navbar-fixed-side .navbar-nav>li>a:hover,
      .navbar-fixed-side .navbar-nav>li>a:focus,
      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a:hover,
      .navbar-fixed-side .navbar-nav .dropdown-menu>li>a:focus {
          color: #000;
      }

      .navbar-fixed-side .dropdown>.dropdown-toggle,
      .navbar-fixed-side .dropdown-menu>.dropdown-header {
        border-bottom: none;
    }

    .navbar-fixed-side .navbar-nav>.open>a,
    .navbar-fixed-side .navbar-nav>.open>a:focus,
    .navbar-fixed-side .navbar-nav>.open>a:hover {
		color: #000;
    }
}

@media (min-width: 769px) and (max-width: 1050px) {
    .main .content {
        height: calc(100% - 120px); 
    }
}

.ml8 {
    margin-left: 8px;
}

.invisible {
    visibility: hidden;
}

.form-group label.holler-toggle {
    margin-right: 4px;
    vertical-align: middle;
}
.form-group label.holler-toggle + .control-label {
    margin-bottom: 0;
}
.form-group label.holler-toggle + .control-label + .sub-label {
    margin-left: 30px;
}

.navbar-default { 
    background: #fff; height: 70px; 
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
    margin-bottom: 0;
    position: relative;
    z-index: 4;
}

.navbar .container { padding: 0; width: 100%; }
.navbar > .container > .navbar-header { width: 100%; }

.navbar > .container .navbar-brand {
    height: 40px; width: 40px;
    float: none; color: #528bff;
    margin: 15px 20px; padding: 0;
    display: block;
}

.navbar > .container .navbar-brand > svg {
    fill: currentColor;
}

.navbar-collapse { padding: 0; }

ul.navbar-nav > li.dropdown { min-width: 200px; border-right: 1px solid #e0e0e0; }
ul.navbar-nav > li.dropdown:hover { background: #ebebeb;box-shadow: 0 2px 2px rgba(0,0,0,.3) }
ul.navbar-nav > li.dropdown:hover ul.dropdown-menu { display: block; line-height: normal; } 

ul.dropdown-menu { 
    z-index: 9999;
	border-radius: 0;
    margin: -3px 0 0;
    border: none;
    width: 100%;
    text-transform: uppercase;
	background: #ebebeb;
	box-shadow: 0 2px 2px rgba(0,0,0,.175)
 } 

.dropdown-menu > li a,
.dropdown-menu > li button { 
    font-weight: bold; 
    padding: 8px 12px;
    display:block; 
    width: 100%; 
    text-align: left; 
    border-radius: 0; 
    white-space: nowrap;
    text-decoration: none;
}

.dropdown-menu > li button:hover,
.dropdown-menu > li a:hover { background: #f5f5f5; }
.dropdown-menu > li button:active { box-shadow: none; }
.dropdown-menu > li button:active:focus { outline: transparent ; }

ul.dropdown-menu li a svg,
ul.dropdown-menu li btn svg { margin-right: 8px; vertical-align: text-top; } 

ul.navbar-nav > li { padding: 0px; font-weight: bold; }

.navbar-default .navbar-nav > li a {
    color: inherit;
}

ul.navbar-nav > li > a,
ul.navbar-nav > li > div > div > a,
ul.navbar-nav > li > button, 
ul.navbar-nav > li > .dropdown-toggle { 
    padding: 0 15px; line-height: 70px; 
}

ul.navbar-nav > li > .btn-live-feed.active {
    background: #fcc9c1;
    border-radius: 0;
    color: #333;
}

ul.navbar-nav > li > .btn-live-feed span.new-votes-count {
    display: inline-block;
    width: auto;
    padding: 2px 4px;
    /* height: 16px; */
    background: #FF0000;
    color: #fff;
    position: absolute;
    top: 14px;
    line-height: normal;
    right: 8px;
    border-radius: 4px;
    font-size: 1.2rem;
}

ul.navbar-nav > li > a svg,
ul.navbar-nav > li > div a svg,
ul.navbar-nav > li > button svg { vertical-align: middle }

ul.navbar-nav > li > a .navbar-menu-item-tooltip,
ul.navbar-nav > li > div > div > a .navbar-menu-item-tooltip {
    position: absolute;
    display: none;
    padding: 8px; margin-left: -15px; margin-top: -1px;
    text-transform: uppercase; white-space: nowrap;
    background: #e0e0e0;
    box-shadow: 0 12px 18px rgba(0, 0, 0, 0.2);
}

.navbar-default .navbar-nav > li > a.active,
.navbar-default .navbar-nav > li > a:focus { background: #e0e0e0; border: none; outline: transparent; }

.navbar-default .navbar-nav > li > a.active,
.navbar-default .navbar-nav > li > div > div > a.active { padding-left: 20px; padding-right: 20px; }

ul.navbar-nav > li > a.active .navbar-menu-item-tooltip,
ul.navbar-nav > li > div > div > a.active .navbar-menu-item-tooltip { margin-left: -20px; }

.navbar-default .navbar-nav > li > a:hover { background-color: #e0e0e0; }

.navbar-default .navbar-nav > li > a:hover .navbar-menu-item-tooltip { display: block; }

ul.navbar-nav > li a span,
ul.navbar-nav > li > .dropdown-toggle span { vertical-align: middle; line-height: normal; margin-right: 8px; }

ul.navbar-nav > li a .avatar,
ul.navbar-nav > li > .dropdown-toggle .avatar {
	display: inline-block; 
    border-radius: 1.2rem; background-color: #999; 
}

ul.navbar-nav > li a .name,
ul.navbar-nav > li > .dropdown-toggle .name {
	display: inline-block;
    font-size: 1.6rem;
    max-width: 256px;
    min-width: 168px;
    overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
    
} 

ul.navbar-nav > li a .name small,
ul.navbar-nav > li > .dropdown-toggle .name small { display: block; font-size: 1.2rem; color: #b3b3b3; font-weight: 300; }

/* custom cases css styles */
.entity-nav-item .dropdown-menu { min-width: 280px; }
.modal {
	display: block;
}

.modal-overlay {
	display: block;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	height: 100%; width: 100%;
	z-index: 1000;
	background-color: rgba(0, 0, 0, .55);
}

.modal-container {
	display: block;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0; 
	z-index: 10000;
	overflow: hidden;
	text-align: center;
	padding: 4px;
}

.modal-container:after {
	vertical-align: middle;
	display: inline-block;
	height: 100%;
	margin-left: -.05em;
	content: '';
}

.modal-content {
	position: relative;
	outline: 0;
	width: 100%;
	background: white;
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	vertical-align: middle;
	box-sizing: border-box;
	max-width: 520px;
	max-height: 97vh;
	cursor: default;
	background-color: #f6f6f6;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
}

.modal-title { 
	text-align: left; 
}

.modal-title h1 {
	margin: 12px; 
	font-weight: 900; font-size: 1.8rem; text-transform: uppercase;
}

.modal__close { float: right; color: #333; margin: -2px -4px 0 0 }

.modal-body {
	font-size: 1.6rem;
	vertical-align: middle;
	overflow-x: hidden;
	overflow-y: auto;
}

.modal-footer {
	border: none;
	padding: 8px;
	box-shadow: 0 2px 0px 0px rgba(0, 0, 0, .30);
}

.modal-body.no-padding { padding: 0; }
.confirm-on-close-holder {
    display: inline;
}

.confirm-on-close-overlay{ 
	background: rgba(255,255, 255, 0.9);
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    border-radius: 2px;
    position: absolute;
	z-index: 10003;
	display: -webkit-flex;
	display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.message-confirm-container { text-align: center; }
.message-confirm-container .btn-toolbar { display: inline-block; }

.full-size .message-confirm-container {
    width: 300px; height: 100px;
    top: 50%; left: 50%;
    margin-top: -150px; 
}
.topic-links-modal-heading {
	background: #5cc65c;
	color: #fff; font-size: 1.6rem; text-transform: uppercase;
	padding: 8px 0 32px;
}

.topic-links-modal .check-circle { 
	width: 68px; height: 68px; 
	border-radius: 50%;
	background: #5cc65c;
    padding: 6px 4px;
    margin-top: -31px; margin-left: auto; margin-right: auto;
}

.topic-links-modal-heading h2 { font-weight: bold; margin-top: 8px }
.topic-links-modal-heading p { font-weight: bold }

.topic-links-modal-content { margin: 24px 0 24px; }
.topic-links-modal-content a.topic-url { font-size: 2.4rem; color: #3184d1; }

.topic-links-modal-content .card { width: 300px; padding: 32px; margin: 8px auto 48px; }
.topic-links-modal-content .card.html-snippet-card { width: auto; padding: 6px; margin: 12px; }

.topic-links-modal-content a.download:first-child { margin: 2px 0 0 8px; }

.topic-links-modal-content .embeddable-html-snippet { margin: 35px auto 10px auto; }

.topic-links-modal-content .embeddable-html-snippet .holler-live-vote-img { margin-top: 30px; }
.topic-links-modal-content .embeddable-html-snippet .html-snippet-salutation { text-align: left; }

.topic-links-modal-content .embeddable-html-snippet .html-snippet-preview-box { margin-top: 8px; }
.topic-links-modal-content .embeddable-html-snippet .html-snippet-preview { height: 140px; overflow: auto; }

.topic-links-modal-content a.copy-to-clipboard { 
	font-size: 1.2rem; color: #bbb;
	margin-left: 8px; line-height: 2.4rem;
	display: inline-block;
}

.topic-links-modal-content a.copy-to-clipboard svg {vertical-align: inherit; line-height: baseline; }
.card { 
	background-color: #fff; padding: 8px; margin: 8px; position: relative;
	border-radius: 2px; box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .10); 
}

.card h4 { 
	text-transform: uppercase; font-size: 1.8rem; font-weight: bold;
	margin-bottom: 2.4rem; margin-left: 8px
}

.card.collapsed h4 { margin-bottom: 4px; }

.card h4 .btn { padding: 0; margin-left: 8px; }
.card h4 .btn svg { vertical-align: baseline; }

.card.dark {
	background-color: #666666;
	color: #ebebeb;
}

.card.pad { background-color: #f5f5f5; margin-left: 4px; margin-right: 4px; }

.card.pad .card.pad { background: #f9f9f9; }

.card.options { background-color: #e1eefb; padding-left: 12px; padding-right: 12px; }
.card.options .nl-form { background: transparent; }

.card.dark strong { color: #fff; }

.card.no-h-offset { margin-left: 0; margin-right: 0 }

.card.pad .card { margin-left: 0; margin-right: 0 }

.card-overlay {
	position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.7);
}

.card-overlay p {
	position: relative;
	top: 50%;
	margin-top: -3.2rem;
}


@media (max-width: 769px) {
	.card { margin-left: 4px; margin-right: 4px; }
}
.bsFormControl_bsFormControl__2VMQK {
	position: relative;
}

.bsFormControl_inline__2Nl4d {
	display: inline-block;
}
div.kazvabg-rating-widget__button-wrapper::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        #20e534 0%,
        #1ed931 10%,
        #53b7ff 30%,
        #538cff 50%,
        #c36eff 70%,
        #ff4d40 90%,
        #ff4133 100%
    );
    border-radius: 50px;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 0;
    transition: opacity 0.8s ease, -webkit-filter 0.8s ease;
    transition: filter 0.8s ease, opacity 0.8s ease;
    transition: filter 0.8s ease, opacity 0.8s ease, -webkit-filter 0.8s ease;
}

div.kazvabg-rating-widget__button-wrapper:hover::before {
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 1;
}

div.kazvabg-rating-widget__button-container {
    width: 115px;
    height: 42px;
    position: relative;
}

div.kazvabg-rating-widget__gradient {
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
    transition: -webkit-filter 0.8s ease;
    transition: filter 0.8s ease;
    transition: filter 0.8s ease, -webkit-filter 0.8s ease;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}

div.kazvabg-rating-widget__button-wrapper:hover div.kazvabg-rating-widget__gradient {
    -webkit-filter: blur(5px);
            filter: blur(5px);
    opacity: 0.3;
}

div.kazvabg-rating-widget__button-wrapper:hover div.kazvabg-rating-widget__gradient::before {
    opacity: 0;
}

a.kazvabg-rating-widget__button {
    transition: box-shadow 0.25s ease;
}

a.kazvabg-rating-widget__button:active + div.kazvabg-rating-widget__gradient {
    -webkit-filter: blur(0);
            filter: blur(0);
}

a.kazvabg-rating-widget__button:active {
    box-shadow: 0px 2.839px 8.518px 0px rgba(0, 0, 0, 0.1),
        0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset !important;
}

div.kazvabg-rating-widget__gradient::before {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    background: conic-gradient(transparent 0deg,
            white 45deg,
            white 315deg,
            transparent 360deg);
    border-radius: 50%;
    z-index: 1000;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
    opacity: 1;
    transition: opacity 0.8s ease;
    -webkit-animation: kazvabg-rating-widget__gradient-animation 3s linear infinite;
            animation: kazvabg-rating-widget__gradient-animation 3s linear infinite;
    will-change: transform;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}

@-webkit-keyframes kazvabg-rating-widget__gradient-animation {
    from {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
                transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
                transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes kazvabg-rating-widget__gradient-animation {
    from {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
                transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
                transform: translate(-50%, -50%) rotate(360deg);
    }
}
.toggle_toggle__2e9BS {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 15px;
    margin: auto;
}

.toggle_toggle__2e9BS input {
    display: none;
}
input:checked + .toggle_slider__2o7jj {
    border: 2px solid #2196f3;
}
.toggle_slider__2o7jj {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #5b6064;
    transition: 0.4s;
    border-radius: 15px;
}
input:checked + .toggle_slider__2o7jj:before {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
    border: 2px solid #2196f3;
}
.toggle_slider__2o7jj:before {
    position: absolute;
    content: '';
    height: 9px;
    width: 9px;
    left: 1px;
    bottom: 1px;
    border: 2px solid #5b6064;
    transition: 0.4s;
    border-radius: 50%;
}

.toggle_disabled__37aH6 {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle_disabled__37aH6,
.toggle_disabled__37aH6:before,
.toggle_slider__2o7jj.toggle_inverse__2_ntO,
.toggle_slider__2o7jj.toggle_inverse__2_ntO:before
{
    border-color: #aaa;
}
input:checked + .toggle_slider__2o7jj.toggle_inverse__2_ntO,
input:checked + .toggle_slider__2o7jj.toggle_inverse__2_ntO:before {
    border-color: #fff;
}
input:checked + .toggle_slider__2o7jj.toggle_disabled__37aH6,
input:checked + .toggle_slider__2o7jj.toggle_disabled__37aH6:before {
    border-color: #337ab7;
}
.toggle_slider__2o7jj.toggle_disabled__37aH6.toggle_inverse__2_ntO,
.toggle_slider__2o7jj.toggle_disabled__37aH6.toggle_inverse__2_ntO:before {
    border-color: #eeeeee;
}
input:checked + .toggle_slider__2o7jj.toggle_disabled__37aH6.toggle_inverse__2_ntO,
input:checked + .toggle_slider__2o7jj.toggle_disabled__37aH6.toggle_inverse__2_ntO:before {
    border-color: #eeeeee;
}
.pop-over-container { display: inline-block; }

.pop-over {
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 3;
    background: #fff;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.5);
    text-align: left;
}

.pop-over h4 { padding: 4px 12px 0 }

.pop-over-content { padding: 8px 12px; }

.pop-over.no-padding .pop-over-content { padding-left: 0; padding-right: 0; }

.pop-over-overlay .overlay {
	display: block;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	height: 100%; width: 100%;
	z-index: 1000;
	background-color: rgba(0, 0, 0, .0);
}

.pop-over-caret {
	height: 16px;
    width: 16px;
    position: absolute;
    background: #fff;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.btn-upload {
    position: relative;
	overflow: hidden;
	cursor: pointer;
}

.btn-upload input[type="file"] {
	position: absolute;
	top: 0;
	height: 100%; width: 100%;
	opacity: 0;
	cursor: pointer;
}

.btn-upload span,
.btn-upload svg,
.btn-upload input { cursor: pointer }

.btn-upload[disabled] span,
.btn-upload[disabled] svg,
.btn-upload[disabled] input { cursor: not-allowed }

ul.uploaded-files-list { width: 100%; }

ul.uploaded-files-list li span.file-name {
	display: inline-block;
	width: calc(100% - 24px);
	overflow: hidden;
	text-overflow: ellipsis
}
.contact-form-inner-content {
    max-height: 100%;
    overflow: inherit;
}
.autocomplete {
	height: 34px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    border-radius: 4px;
	border: 1px solid #ccc;
	padding: 8px 12px;
	background: #fff;
}

.autocomplete.disabled {
	background-color: #eee;
    opacity: 1;
}

.autocomplete.has-errors {
	border-color: #fa4e3e;
}

.autocomplete > .glyphicon-chevron-down { float: right; }

.autocomplete > label.selection {
	display: inline-block;
	margin-bottom: 0;
	width: calc(100% - 50px);
	overflow: hidden;
    height: 1.6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.autocomplete label.selection.empty {
	color: #ccc;
}

.autocomplete-container {
	margin: 0 -13px 0; padding: 16px;
	z-index: 9999;
	position: absolute; 
	border-radius: 4px;
	border: 1px solid #ccc;
	border-top: none; border-top-left-radius: 0; border-top-right-radius: 0;
	background: #fff;
}

.autocomplete-dropdown-open {
	position: relative;
	max-height: 400px; margin-top: 16px;
	overflow-y: auto;
}

.autocomplete-options { 
	list-style: none;
	background: #fff; 
	margin: 0; padding: 0;
}

.autocomplete-option {
	font-size: 1.4rem;
	padding: 8px;
	cursor: default;
}

.autocomplete-option-highlighted {
	background: #e1f5fe;
}
.renderByPermission_planNotAvailable__3ocOa {
	position: absolute;
	white-space: nowrap;
	font-size: 1.1rem;
	z-index: 2;
	right: 0;
	min-width: 100%;
	text-align: right;
}

.renderByPermission_planNotAvailable__3ocOa.renderByPermission_small__34JU_ {
	font-size: 0.95rem;
}

button .renderByPermission_planNotAvailable__3ocOa {
	top: -4px; right: -4px;
}

.renderByPermission_planNotAvailable__3ocOa.renderByPermission_left__3o3ms {
	right: auto;
	left: 0;
	text-align: left;
}

.renderByPermission_planNotAvailable__3ocOa.renderByPermission_panel__3qDxy {
	right: auto;
	left: auto;
	text-align: center;
	position: relative;
}

.renderByPermission_planNotAvailable__3ocOa label {
	background: #31708f;
	border: 1px solid #31708f;
	border-radius: 2px;
	padding: 4px 12px 3px 14px;
	display: none;
	line-height: 1.1rem;
	color: #fff;
	text-transform: uppercase;
	white-space: nowrap;
	width: auto;
}
.renderByPermission_planNotAvailable__3ocOa.renderByPermission_small__34JU_ label {
	line-height: 1rem;
}
.renderByPermission_planNotAvailable__3ocOa.renderByPermission_panel__3qDxy.renderByPermission_small__34JU_ label {
	margin-top: 22px;
}

.renderByPermission_planNotAvailable__3ocOa label span {
	position: relative;
	white-space: nowrap;
	display: block;
}

.renderByPermission_planNotAvailable__3ocOa:hover label {
	display: inline-block;
}

.renderByPermission_planNotAvailable__3ocOa.renderByPermission_panel__3qDxy label {
	display: inline-block;
	margin-top: 28px;
}

.renderByPermission_nonFreeAccountIcon__eIthx {
	background: #fff;
	width: 20px; height: 20px;
	border-radius: 50%; padding-top: 2px;
	border: 1px solid #31708f;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	text-align: center;
	line-height: 12px;
}

.renderByPermission_planNotAvailable__3ocOa.renderByPermission_small__34JU_ .renderByPermission_nonFreeAccountIcon__eIthx {
	width: 18px; height: 18px;
}

.renderByPermission_planNotAvailable__3ocOa.renderByPermission_left__3o3ms .renderByPermission_nonFreeAccountIcon__eIthx {
	right: auto;
	left: 0;
}

.renderByPermission_planNotAvailable__3ocOa.renderByPermission_panel__3qDxy .renderByPermission_nonFreeAccountIcon__eIthx {
	right: auto;
	left: 50%;
	margin-left: -10px;
}

.renderByPermission_nonFreeAccountIcon__eIthx svg {
	margin: 0;
}

.renderByPermission_planNotAvailable__3ocOa label { margin-left: 12px; margin-right: 12px; right: 0; z-index: 0; }

.imageDnD div{
    width: 60%;
    height: auto;
    display:inline-block;
    z-index: 1;
    position: relative;
    line-height: 25px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.imageDnD button{
    border: 1px solid #aeaead;
    border-radius: 3px;
    padding: 5px 10px;
    margin-left: 5px;
}

.imageDnD .icon {
    display: block;
    float: none;
    margin: 5px auto;
}

.imageDnD .clearButton {
    position: absolute;
    top: 0;
    right:0;
    z-index: 10;
}
.user-edit-form .user-avatar-upload .control-label {
    position: relative;
    bottom: -22px;
}

.user-edit-form .user-avatar-upload .imageWrapper {
    min-width: auto;
}

.user-edit-form .user-avatar-container.fit-desktop-flex-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.user-edit-form .user-avatar-container .user-avatar-upload.fit-desktop-flex-item {
    -webkit-flex-basis: 33.3%;
            flex-basis: 33.3%;
}

.user-edit-form .user-avatar-container .user-main-data.fit-desktop-flex-item {
    -webkit-flex-basis: 66.6%;
            flex-basis: 66.6%;
}
.user-edit-profile .change-password-section {
	paddding: 8px 8px 0px;
}
.user-edit-profile .change-password-section > * {
	margin: 8px 8px 24px;
}

.user-edit-profile .change-password-section .change-pass-title {
	text-transform: uppercase;
	margin-bottom: 4px;
	display: inline-block;
	max-width: 100%;
	font-weight: 700;
}

.user-change-password { text-align: left; }

.user-change-password form {
    width: 100%;
}
.plansCommon_freeColors__2RRXK {
    background-image: linear-gradient(#ffffff, #e7e7e7);
}

.plansCommon_proColors__IqioV {
    background-image: linear-gradient(#6CCBF0, #3B97C5); 
    color: #ffffff;   
}

.plansCommon_businessColors__3z5J3 {
	background-image: linear-gradient(#4699C8, #3767C4);  
    color: #ffffff;  
}

.plansCommon_enterpriseColors__1GRFZ {
	background-image: linear-gradient(#3861BD, #A738BF); 
    color: #ffffff; 
}

.plansCommon_freeTextColors__3f6VP {
    color: #bbb;
}

.plansCommon_proTextColors__1vDPX {
    color: #3B97C5;   
}

.plansCommon_businessTextColors__2BPk2 {
    color: #3767C4;  
}

.plansCommon_enterpriseTextColors__S9YM4 {
    color: #A738BF; 
}
.mobile-navigation { width: 120px; }

@media (max-width: 769px) {
    .main .mobile-navigation { width: auto; display: block; height: 0; margin-bottom: -7px }

	#menuToggle {
		display: block; position: relative;
		z-index: 1; margin: 12px 8px 0;
		-webkit-user-select: none;
		-moz-user-select: none;
		 -ms-user-select: none;
		     user-select: none;
	  }
		
	/*
	* Just a quick hamburger
	*/
	#menuToggle span.hamburger {
		display: block;
		width: 2.4rem; height: 4px; margin: 5px;
		z-index: 1; position: relative;
		
		background: #cdcdcd; border-radius: 3px;
		
		-webkit-transform-origin: 4px 0px;
		
		        transform-origin: 4px 0px;
		transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
					opacity 0.55s ease,
					-webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
		transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
					background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
					opacity 0.55s ease;
		transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
					background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
					opacity 0.55s ease,
					-webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	}
	
	#menuToggle span.hamburger:first-child { -webkit-transform-origin: 0% 0%; transform-origin: 0% 0%; }
	
	#menuToggle span.hamburger:nth-last-child(2) { -webkit-transform-origin: 0% 100%; transform-origin: 0% 100%; }
	
	/* 
	* Transform all the slices of hamburger
	* into a crossmark.
	*/
	#menuToggle input:checked ~ span.hamburger { 
		opacity: 1;
		-webkit-transform: rotate(45deg) translate(-9px, -12px);
		        transform: rotate(45deg) translate(-9px, -12px);
		background: #232323;
	}
	
	/*
	* But let's hide the middle one.
	*/
	#menuToggle input:checked ~ span.hamburger:nth-last-child(3) { opacity: 0; -webkit-transform: rotate(0deg) scale(0.2, 0.2); transform: rotate(0deg) scale(0.2, 0.2); }
	
	/*
	* Ohyeah and the last one should go the other direction
	*/
	#menuToggle input:checked ~ span.hamburger:nth-last-child(2) { 
		-webkit-transform: rotate(-45deg) translate(-2px, 8px); 
		        transform: rotate(-45deg) translate(-2px, 8px); 
	}

	.mobile-navigation ul {
		position: absolute; top: 0;
		width: 200px; padding: 0; margin: 0;
		
		background: #ebebeb; 
		list-style-type: none;
		-webkit-font-smoothing: antialiased;
		/* to stop flickering of text in safari */
		
		-webkit-transform-origin: 0% 0%;
		
		        transform-origin: 0% 0%;
		-webkit-transform: translate(-100%, 0);
		        transform: translate(-100%, 0);
		
		transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
		
		transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
		
		transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
	  }
	  
	.mobile-navigation ul  { position: absolute; margin-top: 0.4rem; }

	.mobile-navigation ul  > li > a,
	.mobile-navigation ul  > li > button,
	.mobile-navigation ul  > li > .dropdown-toggle { 
		color: #666; line-height: 2.4rem; text-transform: uppercase;
		padding: 16px 8px; font-size: 1.8rem;
	}

	.mobile-navigation ul  > li > a svg { vertical-align: middle }

	.mobile-navigation ul  > li > a.active { 
		background: #ddd; color: #000; font-weight: bold;
	}
}
.cookie-message {
	position: fixed;
	bottom: 32px;
	width: 100%; left: 0;
	padding-left: 64px; padding-right: 64px;
}

@media (max-width: 769px) {
	.cookie-message {
		position: unset;
		overflow: hidden; text-overflow: ellipsis;
		bottom: auto; width:  auto;
		margin-left: 8px; margin-right: 8px; margin-top: 0;
		padding-bottom: 36px; padding-left: 0; padding-right: 0;
	}
}
.dismissable-message { padding: 12px 16px }
.dismissable-message span { width: calc(100% - 96px); vertical-align: middle; display: inline-block; }
.rating-badge { 
	display: inline-block; 
	padding: 8px 0; border-radius: 100%; position: relative; z-index: 0; 
	font-weight: normal; font-size: 1.6rem; color: #fff; text-align: center;
	width: 3.8rem; height: 3.8rem;
}

.rating-badge small { color: #fff; } 

.rating-relative-badge { 
	font-size: 1.2em;
    position: relative;
    z-index: 1;
    top: -0.8em;
    width: 3.2rem;
    height: 3.2rem;
    line-height: 1.6rem;
}

.rating-0 { background-color: #bbb;}
.rating-0-text { color: #bbb }

.rating-1 { background-color: #cc3a3a; }
.rating-1-text { color: #cc3a3a; }

.rating-2 { background-color: #cc3a3a; }
.rating-2-text { color: #cc3a3a; }

.rating-3 { background-color: #e35f77; }
.rating-3-text { color: #e35f77; }

.rating-4 { background-color: #e35f77; }
.rating-4-text { color: #e35f77; }

.rating-5 { background-color: #5fa0e3; }
.rating-5-text { color: #5fa0e3; }

.rating-6 { background-color: #5fa0e3; }
.rating-6-text { color: #5fa0e3; }

.rating-7 { background-color: #32de5e; }
.rating-7-text { color: #32de5e; }

.rating-8 { background-color: #32de5e; }
.rating-8-text { color: #32de5e; }

.rating-9 { background-color: #4af86b; }
.rating-9-text { color: #32de5e; }

.rating-10 { background-color: #4af86b; }
.rating-10-text { color: #32de5e; }
.votes-timeline { margin: 0 -8px; }
.vote-timeline-item { padding: 12px; border-bottom: 1px solid #dcdcdc; max-width: 320px; }
.vote-timeline-item:first-child { padding-top: 0; }
.vote-timeline-item:last-child { border-bottom: none; }

.vote-timeline-item .timeline-item-dot,
.vote-timeline-item .timeline-item-info {
	display: inline-block;
    vertical-align: top;
    line-height: 1.8rem;
}

.vote-timeline-item .timeline-item-dot .rating-badge { width: auto; height: auto }

.vote-timeline-item .timeline-item-info {
	text-transform: uppercase;
    width: calc(100% - 32px);
	padding-left: 10px;
	line-height: 1.6rem
}

.vote-timeline-item strong {
	font-size: 1.8rem;
}

.vote-timeline-item .timeline-item-info small:not(.text-muted) {
	font-weight: bold;
}
.live-feed {
	height: 48px; width: 100%;
	background: linear-gradient(#fcc7bf, #fdd7d0); 
	overflow: hidden;
	position: relative;
	box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
	margin-right: 1px;
	position: relative;
	z-index: 1;
}

.live-feed .scrollable-votes-container { 
	overflow-y: hidden; overflow-x: auto; 
	height: calc(100% + 30px); width: 100%; box-sizing: content-box; 
	position: relative;
}
.live-feed .items-container { 
	overflow: hidden; 
	height: 100%; 
	box-sizing: content-box; 
	padding-right: 196px; 
	position: absolute;
	width: auto;
	white-space: nowrap;
}

.live-feed .vote-timeline-item {
	display: inline-block;
	overflow: hidden;
	vertical-align: top;
	padding: 0 8px;
	max-width: 256px;
	background: none;
	transition: all 0.2s ease-in;
}

.live-feed .vote-timeline-item.animate {
	background: #f9bcb5;
	padding-bottom: 8px;
}

.live-feed .vote-timeline-item .timeline-item-dot,
.live-feed .vote-timeline-item .timeline-item-info {
	margin-top: 8px;
}

.live-feed .vote-timeline-item small.topic-name { 
	white-space: nowrap; text-overflow: ellipsis; 
	width: 100%; overflow: hidden;
	display: block; 
}

.live-feed .vote-timeline-item small.age-gender,
.live-feed .vote-timeline-item small.location,
.live-feed .vote-timeline-item small.sources {
	display: none; 
}

.live-feed .right-controls,
.live-feed .left-controls {
	position: absolute; width: 196px; height: 48px;
	right: 0; top: 0; text-align: right; padding-right: 16px;
	background: linear-gradient(#fcc7bf, #fdd7d0);
	box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.2);
	white-space: nowrap;
}

.live-feed .left-controls {
	right: auto; left: 0; width: 36px
}

.live-feed .right-controls label {
	line-height: 48px; font-weight: 100; font-size: 2.2rem; text-transform: uppercase;
	display: inline-block; margin-left: 16px;
}

.live-feed .right-controls button,
.live-feed .left-controls button {
	border-right: 1px solid #bbb;
	border-radius: 0;
	height: 100%;
}

.live-feed .left-controls button { border: none; }
.sbGraph {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.sbGraph .sbGraphCanvas {
    -webkit-flex: 1 1;
            flex: 1 1;
    min-width: 0;
    max-width: 100px;
}
.mini-chart small {
	opacity: 0.6;
	display: block; margin-top: -4px; 
	line-height: 0px; font-size: 0.9rem; 
	text-transform: uppercase; text-align: center; 
}

.mini-chart .text-in-bar {
	display: block; margin-top: 15px;
}

.mini-chart .custom-label-title {
	font-size: 1.1rem;
	opacity: 0.6;
	text-transform: uppercase;
}
.mini-chart .custom-label-value {
	font-size: 1rem;
	text-transform: uppercase;
	font-weight: bold;
}
.lifetime-statbox {
    white-space: nowrap;
    display: inline-block;
    margin-right: 8px;
}

.lifetime-statbox .stats-box {
    display: inline-block; 
    color: #666666; text-transform: uppercase;
    margin-left: 16px;
}

.lifetime-statbox hr { 
    display: inline-block; 
    width: 24px; margin: 0 8px;
    background: transparent; border-bottom: 1px dotted #bbbbbb;
    vertical-align: text-top;
}

.lifetime-statbox .stats-box:last-child,
.lifetime-statbox .stats-box:first-child {
    margin-left: 0px;
}

.lifetime-statbox hr + div.stats-box { margin-left: 0; }

.lifetime-statbox .stats-text,
.lifetime-statbox .stats-icon { display: inline-block; }

.lifetime-statbox .stats-icon {
    margin-right: 6px; margin-left: 6px;
}

.lifetime-statbox .stats-text strong, 
.lifetime-statbox .stats-text label {
    display: block;
    margin: 0;
}

.lifetime-statbox .stats-text label { font-size: 1.1rem; font-weight: 100; color: #bbbbbb; }
.lifetime-statbox .stats-text strong { font-size: 1.6rem; font-weight: 300; }


@media (max-width: 1567px) and (min-width: 1367px) {
    .lifetime-statbox strong {
        font-size: 12px;
    }
    .lifetime-statbox .first-vote,
    .lifetime-statbox .last-vote {
        min-width: 70px;
    }
    .lifetime-statbox small {
        font-size: 60%;
    }
}

@media (max-width: 1367px) and (min-width: 769px) {
    .lifetime-statbox strong {
        font-size: 11px;
    }
    .lifetime-statbox .first-vote,
    .lifetime-statbox .last-vote {
        min-width: 65px;
    }
    .lifetime-statbox small {
        font-size: 50%;
    }
}
.topic-tree-scroll-container{ height: calc(100% - 201px) }
.topics-tree{ min-height: calc(100% - 8px); }

.level-0 { background-color: #f8f8f8; }
.level-1 { background-color: #eee; margin-left: 8px; margin-right: 8px; }
.level-2 { background-color: #ddd; margin-left: 16px; margin-right: 16px; }
.level-3 { background-color: #d1d1d1; margin-left: 20px; margin-right: 20px; }
.level-4 { background-color: #ccc; margin-left: 24px; margin-right: 24px; }
.level-5 { background-color: #c1c1c1; margin-left: 28px; margin-right: 28px; }
.level-6 { background-color: #c1c1c1; margin-left: 32px; margin-right: 32px; }
.level-7 { background-color: #c1c1c1; margin-left: 36px; margin-right: 36px; }
.level-8 { background-color: #c1c1c1; margin-left: 40px; margin-right: 40px; }

.common-filters-bar {
	color: #888;
	font-size: 1.6rem; text-transform: uppercase; font-weight: 300;
	margin-bottom: 8px; margin-top: 8px;
}

.common-filters-bar .common-options-selector > *,
.common-filters-bar .common-options-selector .option {
	font-size: 1.6rem;
	font-weight: 700;
}

.search-topics { margin-bottom: 32px; width: 100%; }

.form-inline .search-topics span.input-group-addon { 
	border-right: none; border-left: none; border-top: none; background: none; 
	padding: 8px;
	width: 48px;
}

.search-topics input.form-control { 
	padding: 8px; 
	border-right: none; border-left: none; border-top: none; box-shadow: none;
	font-size: 1.8rem; line-height: 2.4rem; text-transform: uppercase;  font-family: inherit;
}

.topic-tree-item { position: relative; z-index: 0; }
.topic-tree-item span.expand-collapse {
	cursor: pointer;
	height: 100%;
	padding: 10px 15px 0 15px;
	margin: 0;
	line-height: 3.4em;
}

.list-group { position: relative; z-index: 1; margin-bottom: 5px }

.list-group-item { 
	position: relative;
	z-index: 1;
	display: -webkit-flex;
	display: flex;
	padding: 0;
}

.topic-tree-item-link {
	padding-top: 10px;
	color: #333;
	line-height: 2.4em;
	font-size: 1.6rem;
	-webkit-flex: 3 1;
	        flex: 3 1;
}

.level-0 .topic-tree-item-link { font-size: 2.4rem; font-weight: 700; }
.level-1 .topic-tree-item-link { font-size: 1.8rem; font-weight: 500 }
.level-2 .topic-tree-item-link { font-size: 1.6rem; font-weight: 400; }

.topic-tree-item-link:hover { color: #337ab7 }
.topic-tree-item-link:hover { text-decoration: none; }
.topic-tree-item-link span { display: inline-block; line-height: 2.4rem; }
.topic-tree-item-link small { opacity: 0; font-size: 1.2rem; vertical-align: middle; }

.topic-tree-item-button {
	color: #777;
}

.topic-tree-item.stacked { z-index: -1; }
.topic-tree-item > .stackedOverlay { position: relative; z-index: 2; }

.list-group-item.stacked { 
	width: 100%; max-height: 57px;
	overflow: hidden;
	position: absolute; z-index: 0; 
	margin: -10px auto 0; 
	-webkit-transform: scale(0.99, 0.95); 
	        transform: scale(0.99, 0.95); 
	-webkit-filter: blur(2px) brightness(0.9); 
	        filter: blur(2px) brightness(0.9)
	/* background: #ddd; */
}

.list-group-item.level-1.stacked { 
	-webkit-transform: scale(0.97, 0.95); 
	        transform: scale(0.97, 0.95); 
}

.show-more-list-items {
	margin: 8px auto 0;
	display: block;
	width: 150px;
	z-index: 100;
}

.topic-tree-item > .list-group-item { padding-bottom: 0; border-radius: 0; border: none; margin-bottom: 1px; }
.topic-tree-item > .list-group-item:hover .topic-tree-item-link small { opacity: 1; }

.rating-bar {
	padding: 10px 15px 0 0;
	width: 215px;
	text-align: right;
	font-size: 1.2rem;
	font-weight: 300;
}
.rating { text-align: center; margin-left: 8px; display: inline-block; vertical-align: top;  }

.rating-relative-badge { 
    position: relative;
    z-index: 1;
    top: -0.8em;
}

.votes-volume-container { padding-top: 8px; vertical-align: top; }
.votes-volume-container span { line-height: 2.2rem }
.votes-volume-container small { margin-top: 8px; display: inline-block }

.list-group-item .mini-chart {
	background: rgba(255, 255, 255, 0.6);
	padding: 12px 8px 8px; 
	margin: 0; 
}

.list-group-item .mini-chart canvas { -webkit-filter: blur(0.6px) opacity(50%); filter: blur(0.6px) opacity(50%); }
.list-group-item .mini-chart small { opacity: 0; }

.list-group-item:hover .mini-chart canvas { -webkit-filter: blur(0.6px) opacity(100%); filter: blur(0.6px) opacity(100%); }
.list-group-item:hover .mini-chart small { opacity: 0.6; }

.filterInput {
	border-left: none; border-right: none; border-top: none;
	margin-left: 4px; margin-right: 4px; padding: 8px;
	background-color: #ebfdfb;
}

.entity-name { 
	font-weight: 900; font-size: 3.6rem;
    text-transform: uppercase;
    margin: 0 12px 16px; line-height: 60px; min-height: 60px;
}

.entity-name .btn-toolbar { display: inline-block; }

/* TOPIC DETAILS PAGE */
.subpages-nav {
	box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .10);
	margin: 8px 4px -8px;
	height: 5.6rem;
	display: -webkit-flex;
	display: flex;
}

.subpages-nav .tab {
	-webkit-flex: 1 1;
	        flex: 1 1;
	height: 100%; 
	line-height: 5.6rem; text-align: center; font-size: 1.6rem; text-transform: uppercase;
	color: #777;
	border-radius: 2px;
	background-color: #ddd;
	box-sizing: border-box;
	display: inline-block;
	text-decoration: none;
	overflow: hidden; text-overflow: ellipsis;
}

.subpages-nav .tab svg { vertical-align: text-top }

.subpages-nav .tab:hover { background-color: #eee; color: #333; }
.subpages-nav .tab.active { background-color: #f5f5f5; color: #333; }

.btn-non-free-account { position: relative; margin-right: 8px }

/* TOPIC DETAILS */
.votes-filters { 
	margin-left: 3px; margin-right: 3px;
    border-bottom: 8px solid;
    border-top: 8px solid;
	box-shadow: 0 8px 24px;
}

.topic-details-page .stick-top { position: -webkit-sticky; position: sticky; top: -24px; }
.topic-details-page .votes-filters-wrapper { z-index: 3; }

.votes-filters .card-content { display: block; padding: 4px 0 4px 4px }
.votes-filters .filter-votes-title-text { max-width: 240px; display: inline-block; }

.votes-filters .filter-controls { 
	display: -webkit-inline-flex !important; 
	display: inline-flex !important;
	-webkit-align-self: flex-start;
	        align-self: flex-start; 
}
.votes-filters .filter-controls .votes-range { min-width: 240px; display: inline-block; vertical-align: top; }
.votes-filters .filter-controls .votes-filters-details-container { -webkit-flex: 1 1; flex: 1 1; }

.votes-filters .actions { width: 146px; float: right;  vertical-align: top; }

.votes-filters.votes-filters-expanded .filter-votes-title-text { min-width: calc(100% - 36px); }

.votes-filters.votes-filters-expanded .filter-controls { width: 100%; margin-top: 8px; margin-bottom: 8px; }
.votes-filters.votes-filters-expanded .filter-controls .votes-range { margin-left: 30px; }
.votes-filters.votes-filters-expanded .actions { width: 36px; float: none; display: inline-block; }

.votes-filters .filter-votes-title-text .text-label { cursor: pointer; }

.votes-filters .time-range-selector { -webkit-flex: 1 1; flex: 1 1; white-space: nowrap; margin-right: 16px; }
.votes-filters .time-range-selector.custom-range { min-width: 460px }
/* .votes-filters .collapsed .time-range-selector { } */

.votes-filters .votes-filters-sources-textbox { background: transparent; height: 120px; overflow: auto; }
.votes-filters .votes-filters-sources-textbox.autocomplete-search-input { height: 40px; color: #555; }

.votes-filters .votes-filters-sources-textbox input { background: transparent; color: #ebebeb; }
.votes-filters .votes-filters-sources-textbox input::-webkit-input-placeholder { color: #ebebeb; opacity: 1; }
.votes-filters .votes-filters-sources-textbox input::-ms-input-placeholder { color: #ebebeb; opacity: 1; }
.votes-filters .votes-filters-sources-textbox input::placeholder { color: #ebebeb; opacity: 1; }

.votes-filters .votes-filters-sources .text-label { margin-top: 4px; margin-right: 16px; text-align: right; display: block; }

.votes-filters .filter-toggles { -webkit-flex: 1 1; flex: 1 1 }
.votes-filters .filter-toggles div[class^="col-md"] { padding-left: 0; padding-right: 0; }
.votes-filters .filter-toggles div[class^="col-md"].votes-filters-sources { padding-right: 32px; }

.votes-filters label.filter-toggles { cursor: pointer; }

.votes-filters .filter-toggles .btn-toolbar { position: absolute; bottom: 16px; right: 16px; }

/* QNA PAGE */
.questionnaire-container .stats-bar { display: -webkit-flex; display: flex; }
.questionnaire-container .stats-bar .progress { -webkit-flex: 1 1; flex: 1 1; margin-bottom: 8px }
.questionnaire-container .stats-bar label { width: 108px; margin-left: 8px; }

.questionnaire-container .expand-card-footer {
	cursor: pointer;
	width: calc(100% + 17px); 
	margin-left: -8px; margin-bottom: -8px;
	text-align: center;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 20%,rgba(0,0,0,0.1) 85%);
}

.question-details-item h4 {
    position: relative;
    z-index: 1;
}

.question-details-item .question-details-expand {
	opacity: 0.5; margin-top: 16px;
	border-color: rgba(0, 0, 0, 0);
	position: relative; z-index: 2;
}

.question-details-item:hover .question-details-expand {
	opacity: 1;
	border-color: #999
}

.topic-details-qna .questions-list-options-bar { 
	background-color: #e2e2e2; 
	padding: 8px;
	position: relative;
	border-radius: 2px; 
	box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .10); 
	font-size: 1.5rem;
	font-weight: 300;
	margin: 10px 0 14px 0;
}

@media (min-width: 769px) {
	.topics-tree { margin: 0 4px; padding: 16px; }
	.votes-volume-container { display: inline-block; }

	.group-by-vote-container .group-vote-item { display: -webkit-flex; display: flex; }

	.group-by-vote-container .group-vote-item .vote-question-text { -webkit-flex: 1 1; flex: 1 1; }
	.group-by-vote-container .group-vote-item .vote-answer-text { -webkit-flex: 1 1; flex: 1 1; }
}

@media (max-width: 769px) {
	.list-group-item.level-1 { margin-left: 0; margin-right: 0; }
    .list-group-item.level-2 { margin-left: 2px; margin-right: 0; }
    .list-group-item.level-3 { margin-left: 4px; margin-right: 0; }
    .list-group-item.level-4 { margin-left: 6px; margin-right: 0; }
    .list-group-item.level-5 { margin-left: 8px; margin-right: 0; }
    .list-group-item.level-6 { margin-left: 10px; margin-right: 0; }
    .list-group-item.level-7 { margin-left: 12px; margin-right: 0; }
    .list-group-item.level-8 { margin-left: 14px; margin-right: 0; }


	.page-heading, .entity-name { margin: 5px 10px 10px 4.0rem; min-height: 3.0rem; }
	
	.page-heading .btn-toolbar,
	.entity-name .btn-toolbar { line-height: 3.6rem; }

	.list-group-item .mini-chart { display: none; }
	
    .topic-tree-item-link { line-height: 0; margin-top: 4px; width: calc(100% - 70px); vertical-align: super; overflow: hidden; text-overflow: ellipsis; }
    .topic-tree-item-link span { vertical-align: bottom; }
	.topic-tree-item span.expand-collapse { vertical-align: bottom; line-height: 3rem; }
	
	.subpages-nav { height: 4.2rem; }
	.subpages-nav .tab { font-size: 1.4rem; line-height: 4.2rem; }

	.rating-bar { width: 50px; }

	/* Votes filters */
	.votes-filters.sticky { 
		top: 0;
	    margin-top: 0;
		border-top-width: 0;
		margin-left: 0;
	}

	.votes-filters .time-range-selector.custom-selected { min-width: 100% }
	.votes-filters .filter-controls { display: inline-block !important; }
	.votes-filters.votes-filters-expanded .filter-controls { padding-left: 30px }
	.votes-filters .filter-controls .votes-filters-details-container { -webkit-flex: none; flex: none; margin-top: 8px; }
	.votes-filters.votes-filters-expanded .filter-controls .votes-range { margin-left: 0; }
	.votes-filters .votes-filters-sources .text-label { text-align: left; }

	.votes-filters label.filter-toggles { cursor: pointer; }
	.votes-filters .actions { width: 32px; position: absolute; right: 0; top: 8px; margin-left: -32px; }

	.votes-filters .filter-toggles div[class^="col-md"].votes-filters-sources { margin-bottom: 8px; }

	.question-details-list > .col-md-12{
		padding-left: 0; padding-right: 0;
	}
	
	.topic-details-page .stick-top { position: -webkit-sticky; position: sticky; top: -14px; }
}

@media (max-width: 576px) {
	.subpages-nav .tab .tab-text {
		display: none;
	}
}

.topics-overview-header { display: -webkit-flex; display: flex; margin-bottom: 16px; }

.topics-overview-header .page-heading { 
	-webkit-flex: 1 1; 
	        flex: 1 1; 
	overflow: hidden; text-overflow: ellipsis; white-space: nowrap; 
	margin-right: 24px;
	margin-bottom: 0;
}

.topics-overview-header .lifetimestats-container {	max-width: 674px; margin-right: 8px; height: 38px; margin-top: 10px; }
.topics-overview-header .lifetimestats-container .btn { vertical-align: text-bottom };

.topics-overview.content-with-stats .stats-container { margin-bottom: 16px; }


@media (max-width: 769px) {
	.content-with-stats .topics-tree-container { width: 100% }
}
.calendar {
  width: 455px;
  margin: auto;
  position: absolute;
  z-index: 9999;
  background: #fff;
  /* padding: 8px; */
  box-shadow: 0px 0px 50px rgba(0,0,0,0.5);
  left: auto; right: auto;
  left: calc(50% - 222px)
}
.calendar .calendar__range {
  color: #5d5d5d;
  margin-bottom: 10px;
  padding: 8px 16px 0;
}

.calendar .close-date-range {
  position: absolute;
}

.calendar .calendar__range .calendar__from-date {
  min-width: 170px;
  vertical-align: top;
  min-height: 60px;
}
.calendar .calendar__range .calendar__from-date,
.calendar .calendar__range .calendar__image-arrow {
  display: inline-block;
}
.calendar .calendar__range .calendar__image-arrow {
  font-size: 2.5rem;
  color: #dbd7db;
  margin-right: 30px;
  vertical-align: middle;
}
.calendar .calendar__range .calendar__range-h {
  font-size: 1.2rem;
  text-transform: uppercase;
}
.calendar .calendar__range .calendar__range-month {
  font-size: 1.5rem;
}
.calendar .calendar__range .calendar__range-date {
  font-size: 2.3rem;
  line-height: 2.3rem;
  font-weight: 100;
  margin: 0 5px 0 -5px;
}
.calendar .calendar__range .calendar__range-day {
  font-size: 0.9rem;
  color: #a2a2a2;
  font-weight: 100;
}
.calendar .calendar__range td {
  padding: 0;
  line-height: 1.6rem;
}
.calendar .calendar__chooser {
  background: #fffdff;
  text-align: center;
}
.calendar .calendar__header {
  text-align: center;
  width: 455px;
  padding-top: 13px;
  border-top: 1px solid #efefef;
  margin-bottom: 4px;
}
.calendar .calendar__header .calendar__month-chooser {
  color: #5d5d5d;
  font-size: 1.5rem;
  margin-bottom: 16px;
  width: 455px;
  padding: 0 30px;
}
.calendar .calendar__header .calendar__month-chooser .calendar__prev-month {
  float: left;
}
.calendar .calendar__header .calendar__month-chooser .calendar__next-month {
  float: right;
}
.calendar .calendar__header .calendar__month-chooser .calendar__prev-month,
.calendar .calendar__header .calendar__month-chooser .calendar__next-month {
  cursor: pointer;
  color: #a2a2a2;
  font-size: 1.4rem;
  line-height: 1.4rem;
}
.calendar .calendar__days-names {
  color: #a2a2a2;
  font-size: 0.8rem;
}
.calendar .calendar__days-names .calendar__day-name {
  min-width: 65px;
}
.calendar .calendar__wrap {
  max-height: 301px;
  overflow: hidden;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.calendar .calendar__table {
  transition: all 0.3s ease-in;
  cursor: pointer;
  will-change: transform;
}
.calendar .calendar__table.animate--forward {
  -webkit-animation: list-forward 0.3s forwards;
          animation: list-forward 0.3s forwards;
}
.calendar .calendar__table.animate--backward {
  -webkit-animation: list-backward 0.3s forwards;
          animation: list-backward 0.3s forwards;
}
.calendar .calendar__table .calendar__day {
  z-index: -2;
  min-width: 65px;
  padding: 0;
  font-size: 1.4rem;
  text-align: center;
  color: #5d5d5d;
  border-bottom: 1px solid #fff;
}
.calendar .calendar__table .calendar__day:hover .calendar__inner-day {
  border-radius: 100%;
  border: 2px solid #349aef;
}
.calendar .calendar__table .calendar__day.dis {
  background: #fcf9fc;
  border-bottom: 1px solid #fcf9fc;
}
.calendar .calendar__table .calendar__day.sel {
  background: #349aef;
  color: #fff;
}
.calendar .calendar__table .calendar__day.sel--start {
  color: #fff;
}
.calendar .calendar__table .calendar__day.sel--start .calendar__inner-day {
  border-radius: 49px;
  border: 2px solid #349aef;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #349aef;
  margin-right: 0;
}

.calendar .calendar__table .calendar__day.sel--end .calendar__inner-day {
  border-radius: 0 50% 50% 0;
  border: 2px solid #349aef;
  background: #fff;
  margin-left: 0;
}
.calendar .calendar__table .calendar__day.out--range {
  color: #e3e3e3;
}
.calendar .calendar__table .calendar__day .calendar__inner-day {
  height: 49px;
  width: 49px;
  line-height: 45px;
  border-radius: 100%;
  border: 2px solid transparent;
  margin: auto;
}
@-webkit-keyframes list-forward {
  0% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  100% {
    -webkit-transform: translate3d(0px, -200px, 0px);
            transform: translate3d(0px, -200px, 0px);
  }
}
@keyframes list-forward {
  0% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
  100% {
    -webkit-transform: translate3d(0px, -200px, 0px);
            transform: translate3d(0px, -200px, 0px);
  }
}
@-webkit-keyframes list-backward {
  0% {
    -webkit-transform: translate3d(0px, -200px, 0px);
            transform: translate3d(0px, -200px, 0px);
  }
  100% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}
@keyframes list-backward {
  0% {
    -webkit-transform: translate3d(0px, -200px, 0px);
            transform: translate3d(0px, -200px, 0px);
  }
  100% {
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
  }
}

.options-selector { 
	display: inline-block;
	margin: 0 8px;
}

.options-selector .selection-label {
	display: -webkit-flex;
	display: flex;
	background: #e7f8fd; 
	color: #337ab7; 
	cursor: pointer;
	padding: 4px 8px;
	margin: 0;
}
.options-selector .selection-label > button {
	border: 0;
	padding: 0;
	font-weight: normal;
}

.options-selector .options-dropdown { 
	visibility: hidden;
	opacity: 0;
	padding: 2px 0px;
	position: absolute; z-index: 9999;
	transition:visibility 0.2s ease-out, opacity 0.2s ease-out;
	background: #60a0d4;
	max-height: 300px;
	overflow: auto
}

.options-selector .options-dropdown.open { 
	visibility: visible; opacity: 1; overflow: auto; overflow-x: hidden;
}

.options-selector .options-dropdown li.search input[type="text"] {
	background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    width: calc(100% - 16px);
	margin: 8px; margin-left: -1.8rem;
	position: relative;
	color: #fff; padding-left: 2.2rem;
}

.options-selector .options-dropdown li.search svg { margin-left: 8px; position: relative; color: #fff; }

.options-selector .options-dropdown .option { 
	color: #ddd;
	padding: 2px 8px;
	cursor: pointer;
	position: relative;
}

.options-selector .options-dropdown .option:hover { background: #82b8e4 }

.options-selector .options-dropdown .option.selected {
	color: #fff; 
}

.options-selector .options-dropdown .option.disabled {
	cursor: not-allowed;
	background: #638cad;
	color: #afafaf;
}

.options-selector .options-dropdown .option .non-free-account-icon {
	position: absolute;
	top: -7px; right: -7px;
	background: #fff;
	width: 20px; height: 20px;
	border-radius: 50%; padding: 1px 0 0 2px;
	border: 1px solid #aaa
}

.options-selector-overlay {
	position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9998;
}
.stats-card h4 { 
	font-size: 1.2rem; font-weight: normal;
	margin: 0 0 8px; text-align: left;
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.overal-rating-bar { display: -webkit-flex; display: flex; }

.overal-rating-bar .rating { -webkit-flex: 1 1; flex: 1 1; text-align: left; display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; }
.overal-rating-bar .rating .rating-badge { width: 40px; margin-right: 10px; }
.overal-rating-bar .rating .rating-label { -webkit-flex: 1 1; flex: 1 1 }

.rating-bar .mini-chart { width: 100px; margin-left: 10px; }

.stats-card .flex .stats-chart { width: 50px; margin-right: 8px; }

.stats-card .flex .stats-info { -webkit-flex: 1 1; flex: 1 1; color: #bbbbbb; text-transform: uppercase }
.stats-card .flex .stats-info .numbers { font-size: 2.4rem; display: block; line-height: 3.8rem; }
.stats-card .flex .stats-info .description { font-size: 1.1rem; display: block; line-height: 1.0rem; }
.stats-card .flex .stats-info .description label { color: #000; font-weight: normal; line-height: 1.4rem }
.overall-micro-stats .stats-card { text-align: left; }
.overall-micro-stats .stats-card.empty .card-content { padding-top: 16px; }

.overall-micro-stats .stats-card > label {
	margin-bottom: 5px; margin-left: 4px;
    display: block;
}

.overall-micro-stats .stats-card label > a {
    display: inline-block; 
    color: inherit; 
    margin-left: 8px;
    max-width: calc(100% - 70px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.overall-micro-stats .stats-card:hover label > a { color: #337ab7; }

.overall-micro-stats .stats-card label > a:hover { text-decoration: underline; }

@media (max-width: 1367px) and (min-width: 769px) {
    .overall-micro-stats .stats-col.always-horizontal .stats-card .stats-info .numbers { margin: 28px 0; display: inline-block; }
    .overall-micro-stats .stats-card { height: 125px; }
}
.ordered-form-fields  .ordered-form-fields-item .autocomplete-search-input {
	display: none;
}

.autosuggest-tags-selector { 
	min-height: 38px;
    height: auto;
	padding-left: 4px; padding-bottom: 0;
	position: relative; 
}

.autosuggest-tags-selector > .glyphicon { vertical-align: top; padding-top: 6px; }

.autosuggest-tags-selector .autocomplete-dropdown-open { margin-top: 0; }

.autosuggest-tags-selector label.selections { width: calc(100% - 16px); margin-bottom: 0; }

.autosuggest-tags-selector .tag-input-container { white-space: nowrap; }

.autosuggest-tags-selector .search-tag-input { 
	border: none; 
	margin-left: 8px;
	height: 24px;
	min-width: 256px;
}

.autosuggest-tags-selector .tag-input-container small { text-transform: lowercase; font-weight: 300; color: #a0a0a0 }
.autosuggest-tags-selector .tag-input-container small em { border-bottom: 1px dotted #aaa; }

.autosuggest-tags-selector .search-tag-input:focus { border: none; }
.autosuggest-tags-selector .search-tag-input:disabled { background: #fff; cursor: not-allowed; }

.selection-tag {
	text-transform: lowercase;
    border: 1px solid #a6a6a6;
    border-radius: 4px;
	padding: 4px 8px; margin-right: 4px; margin-bottom: 4px;
	background: #dedede;
	line-height: 1.4rem;
	display: inline-block;
}

.autosuggest-tags-selector .autocomplete-container { width: 100%; margin-left: -4px; }

@media (max-width: 769px) {
	.autosuggest-tags-selector .search-tag-input-tips { 
		display: block;
	}

	.autosuggest-tags-selector .search-tag-input { 
		display: block;
	}
}
.content-with-stats { display: -webkit-flex; display: flex; -webkit-flex-flow: row wrap; flex-flow: row wrap; }
.content-with-stats .stats-header { width: 100%; display: -webkit-flex; display: flex; }

.lifetimestats-container { display: -webkit-flex; display: flex; -webkit-align-items: center; align-items: center; margin-left: auto; }
.lifetimestats-container button { margin-left: 1vw; }

.topic-details-header-right-section { display: -webkit-flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; }

.content-with-stats .stats-container { -webkit-flex: 1 1; flex: 1 1; max-width: 320px; width: 320px; }
.content-with-stats .flex { -webkit-flex-wrap: wrap; flex-wrap: wrap; }

.content-with-stats .stats-col.always-horizontal { display: -webkit-flex; display: flex; -webkit-flex-direction: row; flex-direction: row; }
.content-with-stats .stats-col { display: -webkit-flex; display: flex; -webkit-flex-direction: column; flex-direction: column; -webkit-flex: 1 1; flex: 1 1 }

.topic-details-header-container { display: -webkit-flex; display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; }
.topic-details-header-container .buttons-n-stats-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin-left: auto;
}

.buttons-n-stats-container .buttons-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.stats-row { display: -webkit-flex; display: flex; width: 100%; }
.stats-row .stats-card { -webkit-flex: 1 1; flex: 1 1; width: 130px; box-sizing: border-box; overflow: hidden; margin: 3px; }

.content-with-stats .stats-container .direct-avg-rating {
    text-align: center; margin: 0 auto;
}

.content-with-stats .stats-container .direct-avg-rating .related-rating {
    margin-top: -12px;
}

.content-with-stats .stats-container .direct-avg-rating label { font-size: 1.2rem; text-transform: uppercase; font-weight: normal; }

.content-with-stats .content-container { -webkit-flex: 1 1; flex: 1 1 }

.topic-details .entity-name a.topic-details-active-view { display: none; }

.latest-votes-container { -webkit-flex: 1 1; flex: 1 1; max-width: 260px; width: 260px; }

@media (min-width: 1367px) {
    .content-with-stats .stats-container > .flex { display: block; }
    .content-with-stats .flex .stats-col.small { max-width: auto; -webkit-flex-direction: row; flex-direction: row; }
}

@media (max-width: 1367px) and (min-width: 769px) {
    .content-with-stats .stats-container { width: 100%; min-width: 100%; max-width: 100%; }
    .content-with-stats .flex { -webkit-flex-wrap: nowrap; flex-wrap: nowrap; -webkit-flex-direction: row; flex-direction: row; max-width: 100%;}
    .content-with-stats .flex .stats-col.small { max-width: 140px; -webkit-flex-direction: column; flex-direction: column; }
        
    .stats-row .stats-card { 
        min-width: 0; min-height: 104px; 
        
    }
    
    .stats-card h4 { width: 100%; }
}

@media (max-width: 769px) {
    .content-with-stats { -webkit-flex-direction: column; flex-direction: column; min-height: calc(100% - 158px); }

    .content-with-stats .content-container { width: 100%; }
    .content-with-stats .overview-stats-switch { height: 36px; -webkit-flex: none; flex: none; display: block; }
    
    .content-with-stats .stats-container { width: 100%; min-width: 100%; max-width: 100%; }
    .content-with-stats .stats-container > .flex { display: block; }

    .content-with-stats .flex .stats-col.small { max-width: 140px; -webkit-flex-direction: column; flex-direction: column; }
    .content-with-stats .flex .stats-col { width: 100%; -webkit-flex-wrap: nowrap; flex-wrap: nowrap; -webkit-flex-direction: row; flex-direction: row; }
}
.switch_switch__2Mvs1 label {
    text-transform: uppercase;
    vertical-align: top;
}
.switch_toggledLabel__14G3K {
    font-weight: 700;
}
.switch_untoggledLabel__1-7Fc {
    font-weight: normal;
}

.switch_switchToggle__3cB1x {
    margin-top: 1px;
}
.switch_switchToggle__3cB1x span,
.switch_switchToggle__3cB1x span:before,
.switch_switchToggle__3cB1x input:checked + span,
.switch_switchToggle__3cB1x input:checked + span:before {
    border-color: #333333;
}

.switch_disabled__1fEfN label {
    color: #aaa;
}

.switch_disabled__1fEfN .switch_switchToggle__3cB1x span,
.switch_disabled__1fEfN .switch_switchToggle__3cB1x span:before,
.switch_disabled__1fEfN .switch_switchToggle__3cB1x input:checked + span,
.switch_disabled__1fEfN .switch_switchToggle__3cB1x input:checked + span:before {
    border-color: #aaa;
}
.switch_switchToggleInverse__1G1fX span,
.switch_switchToggleInverse__1G1fX span:before,
.switch_switchToggleInverse__1G1fX input:checked + span,
.switch_switchToggleInverse__1G1fX input:checked + span:before {
    border-color: #ffffff;
}
.switch_disabled__1fEfN .switch_switchToggleInverse__1G1fX span,
.switch_disabled__1fEfN .switch_switchToggleInverse__1G1fX span:before,
.switch_disabled__1fEfN .switch_switchToggleInverse__1G1fX input:checked + span,
.switch_disabled__1fEfN .switch_switchToggleInverse__1G1fX input:checked + span:before {
    border-color: #eeeeee;
}
.switch_inverse__3r2Iz label {
    color: #ffffff;
}
.switch_disabled__1fEfN.switch_inverse__3r2Iz label {
    color: #eeeeee;
}

.no-data-overlay {
	background: #fff;
	opacity: 0.7; 
	position: absolute;
	height: calc(100% - 51px);
	bottom: 8px; left: 8px; right: 8px; top: 51px;

	text-align: center;
}

.no-data-overlay label {
	text-transform: uppercase;
	text-align: center;
	position: relative;
	top: 50%;
	margin-top: -4.2rem;
	opacity: 0.85;
}

.voyager-container .no-data-overlay {
	z-index: 1;
	top: 0; left: 0; right: 0; bottom: 0;
	opacity: 0.8
}
.topic-details-graphs .chart-control-options {
	margin-left: 8px;
}
.topic-details-graphs .chart-control-options span {
	font-size: 1.4rem;
	text-transform: uppercase;
	font-weight: 100;
}

.options-bar {
	font-weight: 300;
	margin-top: 12px;
	font-size: 1.5rem;
	display: block;
}

.stacked-horizontal-barchart .options-bar > label {
	padding: 4px 0px;
	margin: 0;
	font-weight: 300;
}

.option-sentiment-veryLow { color: #cc3a3a; }
.option-sentiment-low { color: #e35f77; }
.option-sentiment-medium { color: #5fa0e3; }
.option-sentiment-high { color: #32de5e; }
.option-sentiment-veryHigh { color: #4af86b; }

.option-gender-male { color: #3e6fa3; }
.option-gender-female { color: #a63ea4; }
.option-gender-other { color: #d7d7d7; }

.option-age-group-1 { color: #a6d948; }
.option-age-group-2 { color: #48d984; }
.option-age-group-3 { color: #48d9d4; }
.option-age-group-4 { color: #4895d9; }
.option-age-group-5 { color: #7348d9; }
.option-age-group-6 { color: #d7d7d7; }
.card.dataset-filters-container {
    margin: 24px 0;
    padding: 0;
}

.dataset-filters-container .common-filters-bar {
    height: 4.8rem;
    line-height: 4.8rem;
    margin-left: 8px;
    display: -webkit-flex;
    display: flex;
}

.dataset-filters-container .common-filters-bar > label {
    font-weight: normal;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.dataset-filters-container .common-filters-bar > label strong {
    color: #333;
}

.dataset-filters-container .common-filters-bar .actions {
    font-weight: bold;
    vertical-align: middle;
    height: 100%;
    width: 102px;
}
.dataset-filters-container .common-filters-bar .actions .dataset-toggle {
    margin-left: 4px;
    display: inline-block;
}
.dataset-filters-container .common-filters-bar .actions .btn {
    height: 100%;
    border-left: 1px solid #e1e1e1;
    margin-left: 8px;
    padding-left: 4px;
    padding-right: 4px;
}

.dataset-filters-container .dataset-filters {
    padding: 0 8px 8px;
}

.dataset-filters hr {
    margin: 2px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #f5f5f5;
}

.dataset-filters .dataset-filters.text-muted {
    opacity: 0.7;
}
.dataset-filters h4 {
    margin-left: 0;
    margin-top: 4px;
    margin-bottom: 0;
    padding-top: 8px;
    text-align: right;
}
.dataset-filters h4 .dataset-toggle {
    margin-right: 4px;
}

.dataset-filters h5 {
    margin: 8px 4px 8px;
    display: inline-block;
    width: 120px;
}
.dataset-filters h5 .btn {
    padding: 2px 4px 0;
}
.dataset-filters h5 .btn svg {
    margin-right: 0;
}

.dataset-filters .filter-options-group {
    padding: 0;
    width: calc(100% - 130px);
    display: inline-block;
}

.dataset-filters .filter-option-toggle {
    padding-left: 4px;
    padding-right: 4px;
    text-transform: uppercase;
    line-height: 3.6rem;
    display: inline-block;
    width: 16%;
    min-width: 130px;
    white-space: nowrap;
}

.dataset-filters .filter-option-toggle:last-child {
    border: none;
}

.dataset-filters .filter-option-toggle .dataset-toggle {
    margin-top: 10px;
    margin-right: 4px;
}

.dataset-filters .btn-toolbar {
    display: block;
    margin: 16px 0 8px;
}
.dataset-filters .btn-toolbar .btn {
    float: none;
}

@media (max-width: 769px) {
    .dataset-filters-container .common-filters-bar > label .static-text {
        display: none;
    }
}

.topic-details-feed { 
	background: #fff; 
	margin-left: -8px; margin-right: -8px; 
	padding-left: 8px; padding-right: 8px;
}

.topic-details-feed .subpages-nav {
	padding-top: 12px;
    background: #ebebeb;
    height: 6.8rem;
    margin: 8px -8px;
    box-shadow: none;
	border-top: 1px solid #cccccc;
	display: -webkit-flex;
	display: flex;
}

.topic-details-feed .subpages-nav h5 { width: 272px; line-height: 5.6rem; margin: 0 0 0 16px; }

.topic-details-feed .subpages-nav .tab {
	background: none;
}

.topic-details-feed .subpages-nav .tab.active {
	box-shadow: 0 -2px 2px 1px rgba(0, 0, 0, .03);
	background: #fff;	
}

.topic-details-feed .search-filter-panel { margin-bottom: 16px; }
.topic-details-feed .search-filter-panel input[type="text"] { background: none; }

.topic-details-feed .feed-actions {
	display: -webkit-flex;
	display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.topic-details-feed .group-item .has-attached-image p {
	display: inline-block; 
	width: calc(100% - 130px);
	overflow: hidden; 
	text-overflow: ellipsis;
}

.topic-details-feed .group-item .has-attached-image .pop-over-container { display: inline-block; vertical-align: top; }
.topic-details-feed .group-item .answer-attachment-preview { height: 100%; width: 100%; }

.topic-details-feed .group-item .answer-attachment-preview img {
	max-height: 100%; max-width: 100%;
}
.topic-details-feed .group-item.empty-qna { padding-left: 16px; }

.topic-details-feed .search-text-highlight { display: inline-block; background: rgb(255, 232, 157); padding: 2px; }

/* ------ GROUP BY VOTER ------ */
.grouped-answers.filters-open { min-height: 780px; }
.grouped-answers .group-item:first-child { border-top-width: 4px; }
.grouped-answers .group-item:last-child { border-bottom-width: 4px; }

.grouped-answers .group-item { 
	border-radius: 4px;
	background-color: #fafafa;
	margin-bottom: 2px;
	padding: 4px 16px 4px 0;
}

.group-item .expand-collapse { display: inline-block; margin-right: 4px; }

.group-by-question-container { padding-bottom: 16px; }

.group-by-question-container .group-head-item { 
	padding-right: 8px; 
	display: -webkit-flex; 
	display: flex; 
	cursor: pointer; 
	background: #f5f5f5;
    height: 4.8rem;
    line-height: 4.8rem;
}

.group-by-question-container .group-head-item:hover { color: #337ab7 }

.group-by-question-container .group-head-item h4 { -webkit-flex: 1 1; flex: 1 1; margin-top: 12px; margin-bottom: 12px; margin-left: 0; }

.group-by-question-container .group-head-item .meta { width: 512px; font-size: 1.2rem; vertical-align: middle; text-align: right }
.group-by-question-container .group-head-item:hover .meta { color: #333; }

.group-by-question-container.empty-answers .group-head-item { padding-left: 12px; padding-top: 0; padding-bottom: 0; } 

.group-by-question-container .group-question-item { padding-right: 8px; margin-bottom: 2px; background: #f5f5f5; display: -webkit-flex; display: flex; padding-top: 12px; padding-bottom: 0; padding-left: 18px }
.group-by-question-container .group-question-item .vote-timeline-item { width: 260px; border: none; padding-left: 0; }
.grouped-answers .group-head-item .group-head-left .vote-timeline-item { padding-left: 0; }

.group-by-question-container .group-question-item .question-item-answer-text { -webkit-flex: 1 1; flex: 1 1; }

.group-by-question-container .group-question-item .meta { width: 60px; }

.group-by-vote-container { margin-bottom: 18px; }

.group-by-vote-container .group-head-item { display: -webkit-flex; display: flex; cursor: pointer; padding-top: 8px; }
.group-by-vote-container .group-head-item:hover { color: #337ab7 }

.group-by-vote-container .group-head-item .expand-collapse { margin-top: 8px; margin-bottom: 8px; }

.group-by-vote-container .group-head-item .meta { line-height: 3.4rem; -webkit-flex: 1 1; flex: 1 1; width: 500px; vertical-align: middle; text-align: right; font-size: 1.2rem; }
.group-by-vote-container .group-head-item:hover .meta { color: #333; }
.group-by-vote-container .group-head-item .group-head-left { width: auto; border: none; }

.group-by-vote-container .group-vote-item { padding-top: 8px; padding-bottom: 8px; padding-left: 18px }

.group-by-vote-container .group-vote-item.deprecated, 

.group-by-vote-container .group-vote-item.deprecated svg { vertical-align: middle }

.group-by-vote-container .group-vote-item .vote-question-text { display: inline-block; width: calc(100% - 80px); }
.group-by-vote-container .group-question-item .meta { width: 80px; }

.group-by-vote-container .group-question-item .meta { width: 60px; }

div.group-by-question-list > p.info-text-qna {
	padding: 10px 12px 10px 12px;
}

.group-by-voter-container {
	background: #f1f1f1;
	padding: 8px; margin: 8px 0;
}

.group-by-voter-container .group-by-voter-item > h4 {
	margin: 4px 0;
	font-size: 1.4rem; line-height: 1.8rem; font-weight: normal;
}

.group-by-voter-container .group-by-voter-item > h4 > span {
	display: inline-block; margin-right: 4px; margin-left: 4px;
}

.group-by-voter-container .group-by-voter-item > h4 .opinion-increase {
	color: #00b522; font-weight: bold;
}

.group-by-voter-container .group-by-voter-item > h4 .opinion-decrease {
	color: #cf4d4d; font-weight: bold;
}

.group-by-voter-container .group-by-voter-item .group-by-vote-container .group-item {
	background: #fafafa; padding-right: 8px; margin-bottom: 4px;
}

.group-by-voter-container .group-by-voter-item .group-by-vote-container .group-item.group-head-item {
	margin-bottom: 0;
}

@media (min-width: 769px) {
	.group-by-vote-container .group-vote-item { display: -webkit-flex; display: flex; }

	.group-by-vote-container .group-vote-item .vote-question-text { -webkit-flex: 1 1; flex: 1 1; }
	.group-by-vote-container .group-vote-item .vote-answer-text { -webkit-flex: 1 1; flex: 1 1; }
}

@media (max-width: 769px) {
	.topic-details-feed .subpages-nav h5 { display: none; }

	.topic-details-feed .search-filter-panel { display: block; line-height: 4.8rem; }
	.topic-details-feed label.search-label { display: block; width: 100%; margin-left: 12px; }
	.topic-details-feed .search-filter-panel .form-inline{ -webkit-flex: none; flex: none; }

	.topic-details-feed .btn-filters {
		margin-left: 8px; margin-right: 8px; margin-bottom: 16px;
		width: calc(100% - 16px);
	}

	.topic-details-feed .btn-filters.active { 
		margin-left: 0px; margin-right: 0px; margin-bottom: 0;
		width: 100%; box-shadow: none; 
	}
	
	.topic-details-feed .feed-actions {
		display: block;
	}

	.topic-details-feed .feed-actions > .feed-options-bar { display: block; line-height: 2.4rem; -webkit-flex: none; flex: none; }
	.topic-details-feed .feed-actions > .feed-actions-buttons { white-space: nowrap; -webkit-flex: none; flex: none; display: block; max-width: 284px; padding-left: 8px; }
	.topic-details-feed .feed-actions .btngroup > button span { display: none }

	.topic-details-feed .filters-panel h4 { text-align: left }
	.topic-details-feed .filters-panel h5 { margin: 24px 4px 8px; display: block; width: auto; }

	.topic-details-feed .filters-panel .filter-options-group { display: block; width: auto; }
	.topic-details-feed .filters-panel .filter-option-toggle { width: 50%; }
	
	.grouped-answers .vote-timeline-item .timeline-item-info { 
		vertical-align: middle;
	}
	.grouped-answers .vote-timeline-item br { display: none; }

	.grouped-answers .group-head-item { display: block; }
	.grouped-answers .group-head-item .expand-collapse { display: inline-block; vertical-align: middle; padding-right: 0; }
	.grouped-answers .group-head-item .group-head-left { display: inline-block; vertical-align: middle; padding-right: 0; width: calc(100% - 36px) }

	.grouped-answers .group-head-item > .meta,
	.grouped-answers .group-head-item > .meta { width: 100%; text-align: left; margin-left: 12px; display: block; font-weight: 1.2rem }

	.grouped-answers .group-head-item.expanded > .meta p,
	.grouped-answers .group-head-item.expanded > .meta p {  text-overflow: ellipsis; overflow: hidden; white-space: nowrap }
	
	.group-by-question-container .group-head-item,
	.group-by-question-container.empty-answers .group-head-item { display: block; padding-top: 4px; padding-bottom: 4px; height: auto; }

	.group-by-question-container .group-head-item h4 { -webkit-flex: none; flex: none; }
	.group-by-question-container .group-head-item .meta { display: block; width: 100%; overflow: hidden; text-overflow: ellipsis; text-align: left; line-height: 1.6rem; margin-left: 8px; }

	.group-by-question-container .group-question-item { display: block; }
	.group-by-question-container .group-question-item .vote-timeline-item { width: 100%; display: block; }

	.group-by-question-container .group-question-item .question-item-answer-text { display: inline-block; width: calc(100% - 72px); }
	.group-by-question-container .group-question-item small.meta { display: inline-block; width: 72px; }

	.topic-details-feed .group-item .has-attached-image p {
		width: calc(100% - 50px);
	}
}

.feed-options-bar { -webkit-flex: 2 1; flex: 2 1; line-height: 2.8rem; }
.feed-options-bar .btn { 
	margin-left: 4px; 
	margin-right: 4px; 
}

.feed-options-bar .btn.btn-outline { 
	border-color: #ebebeb;
	color: #ebebeb;
}

.feed-options-bar label { margin-bottom: 0; }
.search-field { 
    width: calc(100% - 8px); margin-left: 4px; margin-right: 4px;
    border: 1px solid #ccc; border-radius: 8px;
    border-right: none; border-left: none; border-top: none;
}

.form-inline .search-field span.input-group-addon {
    border: none;
    background: none;
    padding: 8px; width: 48px;
}

.form-inline .search-field input.form-control {
    padding: 8px;
    border: none;
    box-shadow: none;
    font-size: 1.8rem; line-height: 2.4rem; text-transform: uppercase; font-family: inherit;
}

.form-inline .search-field.has-clear-button input.form-control { width: calc(100% - 80px); }
.form-inline .search-field.has-submit-button input.form-control { width: calc(100% - 80px); }
.form-inline .search-field.has-submit-button.has-clear-button input.form-control { width: calc(100% - 160px); }

.form-inline .search-field button.btn-xs { width: 72px; margin-top: 0.7rem; }
.register-form { 
	max-width: 1280px; min-width: 768px; 
	margin: 0 auto 52px;
}

.register-form .card-content{ 
	width: 100%;
	display: -webkit-flex !important;
	display: flex !important;
}

.register-form .card-content > div {
	-webkit-flex: 1 1;
	        flex: 1 1;
	max-width: 50%;
	padding: 16px 32px;
}

.register-form .greeting { text-align: center; }
.register-form .greeting img { width: 100%; }

.register-form .user-edit-form { padding-left: 0; padding-right: 0 }
.register-form .user-edit-form .form-group { margin-left: 0; }


.register-form .btn-toolbar { line-height: 3.2rem; }
.register-form .btn-toolbar span {
    font-size: 1.8rem; text-transform: uppercase;
    margin: 4px 8px 0;
    vertical-align: middle
}

.register-form .btn-toolbar span a { font-weight: bold; }

.register-form .captcha-container { margin-bottom: 32px; }

@media (max-width: 769px) {
	.register-form {
		min-width: 100%; max-width: 100%; width: 100%;
	}

	.register-form .card-content {
		display: block !important;
	}

	.register-form .card-content > div {
		-webkit-flex: none;
		        flex: none; max-width: 100%; width: 100%;
	}

	.register-form .greeting { display: block; }
	.register-form .user-edit-form { display: block; }
}
.introGuide_thumb__1CYo8 { 
	text-align: center; 
	padding-top: 16px; padding-bottom: 16px; 
	margin-bottom: 16px; 
	background: #e2e2e2;
}
.accountDetailsPage { max-width: 1280px; margin-right: auto; margin-left: auto; }
.accountDetailsPage .flex > .card.organization-details { -webkit-flex: 2 1; flex: 2 1; }
.accountDetailsPage .flex > .card.account-plan { -webkit-flex: 1 1; flex: 1 1; }

.accountDetailsPage .flex > .card:first-child { margin-right: 0; }
.accountDetailsPage .flex > .card:last-child { margin-left: 0; }

.accountDetailsPage .btn-edit { position: absolute; right: 16px; }

.accountDetailsPage p { margin-left: 8px; }

.accountDetailsPage .organization-details .entity-details-page { margin-left: -8px; }
.accountDetailsPage .organization-details .entity-details-page.in-edit { padding-top: 8px; }
.accountDetailsPage .organization-details .entity-details-page::before { top: 0px; }
.accountDetailsPage .organization-details .entity-details-page .panel { margin-left: 8px; }

.accountDetailsPage .account-plan .card-content { padding-left: 8px; }
.accountDetailsPage .account-plan h3 { 
	margin: -16px 0px 24px; 
	text-transform: uppercase; font-weight: bolder; 
}

.accountDetailsPage .account-plan p { margin-left: 0px; margin-bottom: 4px; font-size: 1.6rem; white-space: nowrap; }
.accountDetailsPage .account-plan p .btn { margin-left: 8px; }

.accountDetailsPage .account-plan .features-overview { margin-top: 16px; margin-bottom: 16px;}

.accountDetailsPage .account-plan .btn-cancel { padding-left: 4px; text-decoration: underline; }

.invoice-list a span{
	text-decoration: underline;
}

.invoice-list .invoice-resolve-action a:focus,
.invoice-list .invoice-resolve-action a:hover {
	color: rgba(224, 172, 172, 0.986);
}

@media (max-width: 769px) {
	.accountDetailsPage .flex { display: block; }
	.accountDetailsPage > .flex > .card { display: block; margin-left: 0px; margin-right: 0px; }
}

.side-panel-container {
    top: 70px; right: 2px; bottom: 32px;
    position: fixed;
    width: 60%; z-index: 3;
}

.main .content.live-feed-epxanded .side-panel-container {
    top: 120px;
}

.side-panel {
    position: relative; height: 100%;
    background: #fff;
    box-shadow: 0px 20px 20px 0px rgba(0,0,0,0.2);
}

.side-panel .page-heading {
    border-bottom: 1px solid #ddd;
    line-height: 8.4rem;
    margin: 0;
    padding: 0 16px;
}

.side-panel .page-heading .btn-toolbar { line-height: 8.4rem; }

.side-panel .side-panel-content {
    height: calc(100% - 8.4rem); overflow: auto;
}

.side-panel .side-panel-content.btn-toolbar-visible {
    height: calc(100% - 13.2rem); overflow: auto;
    
}

.side-panel .btn-toolbar.footer-toolbar { 
    height: 4.2rem; line-height: 4.2rem; padding:0 8px;
    border-top: 1px solid #ddd;
}

.side-panel .btn-toolbar.footer-toolbar .btn {
    vertical-align: middle;
}

@media (max-width: 769px) {

    .side-panel-container {
        top: 0px;
        width: 100%;
    }

}
.ordered-topic-list .topic-id-cell {
	width: 455px;
}

.ordered-topic-list .topic-id-cell-content {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	
}
.ordered-topic-list .private-mark {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.ordered-topic-list .private-mark > svg {
	margin-right: 5px;
}
.static-pages-administration .no-data-container {
    text-align: center;
}

.static-pages-administration .no-data-text {
    text-transform: uppercase;
}
.contentEditPage .fileNameFieldSet {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	margin-top: 10px;
}
.contentEditPage .fileNameFieldSet .labelContent {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.contentEditPage .fileNameFieldSet .labelContent > label {
	margin-bottom: 0px;
}

.contentEditPage .mde-container > div {
	height: 100%;
}

.stepContainer {
    border-bottom: 1px solid #e6e6e6;
    padding: 12px 16px;
    margin-left: -8px; margin-right: -8px;
    cursor: pointer; height:  52px;
}

.progressBar .steps-container > div:first-child {
    padding-top: 12px;
}

.progressBar .steps-container > div:last-child {
    border-bottom: none;
}
.stepIndex {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid #9d9d9d;
    color: #9d9d9d;
    text-align: center;
    padding: 1px;
    display: inline-block;
    font-size: 1.2rem;
}
.stepTitle {
    display: inline-block;
    padding: 5px 5px 0;
    color: #6c6c6c;
    text-transform: uppercase;
}

.stepTitle .warn-sign { display: none; color: #e9b34d; vertical-align: text-top; }

.stepDescription {
    color: #c0c0c0;
    font-weight: normal;
    font-size: 1.4rem;
    padding: 18px 0 15px 8px;
    display: none;
    background: #fff;
    position: absolute;
    left: -100%; width: 100%; margin-top: -39px;
    border: 1px solid #e6e6e6;
    border-right: none;
    text-align: center;
    box-shadow: -4px 2px 4px 0px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}

.stepContainer:hover .stepDescription,
.stepTitle.active + .stepDescription { 
    display: block;
}

.stepContainer.active { height: auto; }
.stepTitle.active + .stepDescription { 
    position: relative; 
    padding-left: 28px;
    border: none;
    margin-top: 0; 
    left: auto;
    padding: 0px 0 4px 26px;
    text-align: left;
    box-shadow: none;
    font-size: 1.2rem;
}

.done { color: #1b94d1; }
.stepIndex.done { background-color: #1b94d1; border-color: #1b94d1; }
.stepIndex.done svg { color: #fff; vertical-align: middle; }

.has-warning .warn-sign { display: inline; }

.has-errors {
    color: #fa4e3e;
    border-color: #fa4e3e;
}

.not-started {
    color: #6c6c6c;
}

.required:after {
    content:"*";
    color: #fa4e3e;
    padding-left: 5px;
    font-size: 16px;
}

.active { color: #1b94d1; font-weight: bold; padding-bottom: 4px }

.stepIndex.active {
    border-color: #1b94d1;
}

.topic-component-container {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
}

.basic-topic-details .private-field-containter {
    margin-bottom: 0;
}

.component-label {
    font-weight: bold;
}
.checkbox-label {
    font-size: 14px;
    text-transform: uppercase;
    padding-left: 10px;
}
.label-description {
    width: 100%;
    margin: 0;
    font-size: 13px;
    color: #b7b7b7;
}
.image-dnd-container,
.image-preview {
    padding: 20px 0;
    width: 300px;
    height: 230px;
    margin-right: 20px;
}
.images-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
}

@media (max-width: 767.98px) {
    .single-line-inputs {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
}

.single-line-inputs {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.single-line-inputs > div {
    width: 100%;
}
.slider-selector, .range-slider-selector { height: 40px; }

.rc-slider-rail { height: 3px; background-color: #ccc; }

.rc-slider-track { background-color: #3998c8; }

.rc-slider-handle {
    width: 27px;
    height: 27px;
    margin-top: -12px;
    margin-left: -14px;
    border: solid 4px #f5f5f5;
    background-color: #3998c8;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.rc-slider-dot { border-color: #ccc; }
.rc-slider-dot-active { border-color: #3998c8; }

.rc-slider-tooltip-inner {
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    color: #3998c8;
    font-weight: bold;
}
.topic-builder-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.steps-container {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.topic-builder-container p.overall-server-error {
    font-weight: bold;
    padding: 16px;
    margin: 8px 4px;
}

.progressBar-container {
    width: 300px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.sub-progress .card {
    border-radius: 0 0 4px 4px;
    margin-bottom: -10px;
    margin-left: -8px;
    width: calc(100% + 16px);
}

.meta-info {
    background: #dcdcdc;
}

.update-status-container {
    height: 80px;
    background: #39b54a;
}

.form-control.has-error {
    border-color: #d15e42;
    color: #d15e42;
}

input.dotted,
.form-control.dotted[disabled],
fieldset[disabled] .form-control.dotted {
    border-style: dashed;
    background-color: #fff;
    cursor: default;
}

form .card {
    margin-bottom: 24px;
}

form .form-group {
    margin: 8px 8px 24px;
    clear: both;
}

form .group-panel {
    margin-top: 16px;
}

.form-errors {
    height: 20px;
    overflow: hidden;
}

.form-errors span {
    display: block;
    margin: 4px;
    font-size: 12px;
    text-transform: uppercase;
    color: #d15e42;
}

.form-errors {
    height: 20px;
    overflow: hidden;
}

.form-warnings {
    height: 20px;
    overflow: hidden;
}

.form-warnings span {
    display: block;
    margin: 4px;
    font-size: 12px;
    text-transform: uppercase;
    color: #f5c117;
}

.wizard-controls {
    margin-top: 24px;
}

/* form .row-fluid div[class^="col-md"] { padding: 0; } */
form .row-fluid div[class^='col-md']:first-of-type {
    padding-left: 0;
}
form .row-fluid div[class^='col-md']:last-of-type {
    padding-right: 0;
}

.form-group label {
    text-transform: uppercase;
    margin-bottom: 4px;
}

.form-group.horizontal label {
    padding-top: 6px;
    text-align: right;
}

.form-group.horizontal div[class*='col-md'],
.form-group.horizontal label[class*='col-md'] {
    padding-right: 0;
    text-align: right;
}
.form-group.horizontal div[class*='col-md'] span.control-label {
    text-align: left;
    display: block;
}

.form-group .sub-label {
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #aaa;
    display: block;
}

.ordered-form-fields .ordered-form-fields-item {
    margin-bottom: 8px;
}

.ordered-form-fields .ordered-form-fields-item .form-control,
.ordered-form-fields .ordered-form-fields-item .form-control-container {
    display: inline-block;
    width: calc(100% - 3.4rem);
}

.with-action > .form-control-container {
    display: inline-block;
}

.with-action > .form-control,
.with-action > .form-control-container,
.ordered-form-fields .ordered-form-fields-item.with-action > .form-control-container {
    width: calc(100% - 6.8rem);
}

.with-action .action {
    padding-left: 4px;
    display: inline-block;
    margin-top: 1.4rem;
    vertical-align: top;
}

.with-action .action .confirm-button-holder button {
    margin-top: -0.6rem;
}

.with-action .action svg {
    vertical-align: middle;
    cursor: pointer;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.with-action .action:hover svg {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
}

.ordered-form-fields .ordered-form-fields-item .number {
    vertical-align: top;
    display: inline-block;
    margin-right: 1rem;
    margin-top: 1.4rem;
    width: 2.4rem;
    height: 2.4rem;
    border: 1px solid #888;
    border-radius: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
}

form .config-alerts-container .alert-item-interval-custom.form-group {
    margin: 2px 2px 10px 2px;
}

.config-alerts-inner-container.form-group {
    padding: 8px;
    margin: 0;
}

.config-alerts-inner-container .card {
    margin-top: 0;
    margin-bottom: 2px;
}

.config-alerts-inner-container .alert-item-container .alert-item-interval-buttons {
    margin: 0;
    width: 66.6%;
}

.config-alerts-inner-container .alert-item-container .alert-item-interval-custom {
    margin: 0;
    width: 33.3%;
}

.top-right-question-button {
    margin: 4px 10px 12px;
    position: relative;
    float: right;
}

.duplicate-question-button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

.delete-greeting-button {
    background-color: transparent;
    position: absolute;
    border: none;
    padding: 5px;
}

.selector-disabled {
    background: transparent;
    border-style: dashed;
}

.confirm-greeting-message {
    width: calc(100% - 320px) !important;
    height: auto;
    display: -webkit-inline-flex;
    display: inline-flex;
    position: absolute;
    left: 10px;
    margin-top: -13px;
}

.link-text-description {
    padding: 8px;
}
.link-text-description button {
    border-width: 0;
    padding: 0;
}
.link-preview-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.link-preview {
    background-color: #fff;
    padding: 8px;
    margin-bottom: 8px;
    max-width: 1200px;
}
.link-preview-img {
    display: table-cell;
}
.link-preview-caption {
    display: table-caption;
}
.link-preview-caption > * {
    word-break: break-all;
}
.img-responsive {
    margin: auto;
}
.right-childs-margin-half-vw > :not(:last-child) {
    margin-right: 0.5vw;
}

.translations .translations-footer {
    position: relative;
}

.enforce-lang-toggle-container {
    margin-top: 10px;
}
.enforce-lang-toggle {
    vertical-align: top;
}

.changes-saved {
    margin-top: 4px;
}
.changes-saved svg {
    vertical-align: text-bottom;
}

.backend-error {
    height: auto;
}
.form-errors span,
.backend-error span {
    display: block;
    margin: 4px;
    font-size: 12px;
    text-transform: uppercase;
    color: #d15e42;
}

.animate-down:hover svg {
    margin-bottom: -4px;
}
.animate-up:hover svg {
    margin-top: -4px;
}

.animate-expand:hover svg {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.animate-collapse:hover svg {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}

.animate-shrink {
    transition-delay: 0.05s;
}
.animate-shrink-down {
    -webkit-transform: translateY(calc(100% + 24px));
            transform: translateY(calc(100% + 24px));
}
.animate-shrink-up {
    -webkit-transform: translateY(calc(-100% - 24px));
            transform: translateY(calc(-100% - 24px));
}

@media (max-width: 482px) {
    .confirm-greeting-message {
        width: calc(100% - 20px) !important;
    }
}

.imageWrapper {
    min-height: 150px;
    height: 100%;
    width: 100%;
    min-width: 200px;
    border-width: 2px;
    border-color: #aeaead;
    border-style: dashed;
    border-radius: 5px;
    position: relative;
    background-color: #e1e1e1;
    text-align: center;
}

.imageWrapper img {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.6;
}

.imageWrapperError {
    border-color: #a94442;
}

.topic-configuration .channel-field-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}
.topic-configuration .channel-field-selector {
	width: 100%;
}
.topic-configuration .channel-field-private-mark-wrapper {
	margin-right: 5px;
}

.topic-configuration .channel-field-private-text-wrapper {
	margin-top: 5px;
}
.topic-configuration .channel-field-private-text-wrapper > label {
	margin-bottom: 0;
}

.button-bar-input .buttons-bar {
    padding-right: 10px;
    padding-bottom: 5px;
}

.button-bar-input .selected-button {
    color: #333;
}

.button-bar-input .unselected-button {
    color: #999;
}

.button-bar-input .custom-value-prefix{
    padding: 5px;
    padding-top: 7px;
}

.switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}

.switch-field label {
    float: left;
    display: inline-block;
    width: auto;
    background-color: #f6f6f6;
    color: #acacac;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 6px 14px;
    border-top: 1px solid #acacac;
    border-bottom: 1px solid #acacac;
    border-right: 1px solid #acacac;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
}

.switch-field label:hover {
    cursor: pointer;
}

.switch-field input:checked + label {
    background-color: #3899c6;
    color: white;
}

.switch-field span:first-of-type label {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #acacac;
}

.switch-field span:last-of-type label{
    border-radius: 0 4px 4px 0;
}

.after-vote-config .copy-link-wrapper-disabled-container,
.after-vote-config .share-on-facebook-disabled-container {
    margin-bottom: 4px;
}

.after-vote-config .copy-link-disabled-sublabel,
.after-vote-config .share-on-facebook-disabled-sublabel {
	font-size: 13px;
	font-weight: 300;
}


.slider-container {
    text-align: center;
    padding: 20px;
    width: 100%;
}
.duration-label, .duration-mini-label {
    line-height: 70px;
}
.duration-mini-label  {
    font-size: 12px;
    color: #9d9d9d;
}
.modification-stats {
	font-size: 1.2rem;
	text-transform: uppercase;
	color: #666;
}

.modification-stats svg {
	margin-right: 2px;
	vertical-align: middle;
}

.edit-topic-header-container {
	display: -webkit-flex;
	display: flex;
}
.edit-topic-header-container .modification-stats-container {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	margin-left: auto;
}

.entities-list table tr.entity-inactive {
    color: #b7b7b7;
}

.entities-list .subscription.flex .subscription-icon {
    margin: auto;
}

.entities-list .subscription.flex .subscription-data {
    margin: auto;
}

.entities-list .subscription.flex .label-active {
    color: #5cb85c;
}

.entities-list .subscription.flex .label-expired,
.entities-list .subscription.flex .label-pending {
    color: #898989;
}

.entities-list .subscription.flex .label-expiring {
    color: #d15e42;
}

.name-width {
    width: 180px;
}

.created-on-width {
    width: 55px;
}

.created-by-width {
    width: 70px;
}

.actions-width {
    width: 200px;
}

.alerts-width {
    width: 90px;
}

.active-width {
    width: 90px;
}

.tiers-width {
    width: 70px;
}

.subscription-width {
    width: 120px;
}

@media (max-width: 769px) {
    .name-width {
        width: 24%;
        word-break: break-word;
    }
    
    .actions-width {
        width: 16%;
    }

    .alerts-width {
        width: 10%;
        word-break: break-all;
    }

    .active-width {
        width: 10%;
        word-break: break-all;
    }

    .subscription-width {
        width: 20%;
        word-break: break-word;
    }
}
.users-page { height: 100%; }

.users-page .filters-container { width: calc(100% - 120px); display: inline-block }

.users-page .list-container { height: calc(100% - 78px) }

.users-page .grid-container { height: calc(100% - 52px) }

.user-management-grid-container, .users-grid { height: 100%; }

.users-grid .react-bs-container-body { overflow-x: auto; }
.users-grid .react-bs-table-container { height: calc(100% - 140px) }
.users-grid .react-bs-table table { table-layout: auto; }

.react-bs-container-body { overflow-x: hidden; }
.react-bs-container-body table tr td:last-child { padding-right: 18px; }
.react-bs-container-body table tr.active td:first-child  { border-left: 8px solid #337ab7; }
.react-bs-container-body table tr.checked td:first-child { border-left: 8px solid #ccc; }

.user-edit-form { padding: 8px 8px 0; }

.online-only-filter {
    margin-bottom: 0;
    height: 25px;
}
.userDetails_avatar__3Wsww {
    width: 84px; height: 84px;
	margin-right: 16px; margin-left: 8px;
	text-align: center; 
	border-radius: 42px;
	background: #ddd;
}

.userDetails_avatar__3Wsww img { width: 100%; height: 100%; }
.userDetails_avatar__3Wsww svg { margin-top: 25px; opacity: 0.5; }
.user-details-page.in-edit::before {
	content: " ";
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    height: 100%; width: 100%;
	z-index: 3;
	top: 0;
}

.user-details-page .panel { 
	margin-top: 16px;
	padding-left: 16px; padding-right: 16px; 
	position: relative; 
	height: auto;
}

.user-details-page.in-edit .panel{
	margin-left: 8px; margin-right: 8px;	
}

.user-details-page .in-edit.panel { z-index: 4; padding-top: 16px; padding-bottom: 16px; }

.user-details-page .panel h5 { line-height: 2.6rem; }

.user-details-page .btn-edit { position: absolute; right: 16px; }

.user-details-page .user-entities-grid { margin-top: 24px }
.entity-create .entity-create-form { padding: 16px; }
.entity-details-form .zero-margin {
    margin: 0;
}

.entity-details-form .entity-logo-upload .control-label {
    position: relative;
    bottom: -22px;
}

.entity-details-form .entity-logo-upload .imageWrapper {
    min-width: auto;
}

.entity-details-form .entity-logo-container.fit-desktop-flex-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.entity-details-form .entity-logo-container .entity-logo-upload.fit-desktop-flex-item {
    -webkit-flex-basis: 33.3%;
            flex-basis: 33.3%;
}

.entity-details-form .entity-logo-container .entity-main-data.fit-desktop-flex-item {
    -webkit-flex-basis: 66.6%;
            flex-basis: 66.6%;
}
.entityDetails_entityDetails__1pKbN .entityDetails_logo__2den6 { 
	width: 84px; height: 84px;
	margin-right: 16px; margin-left: 8px;
	text-align: center; 
	border-radius: 42px;
	background: #ddd;
}

.entityDetails_entityDetails__1pKbN .entityDetails_logo__2den6 img { width: 100%; height: 100%; }
.entityDetails_entityDetails__1pKbN .entityDetails_logo__2den6 svg { margin-top: 25px; opacity: 0.5; }
.entity-details-page.in-edit::before {
	content: " ";
	background: rgba(0, 0, 0, 0.3);
	position: absolute;
	height: 100%; width: 100%;
	z-index: 3;
	top: 0;
}

.entity-details-page .panel { 
	margin-top: 16px;
	padding-left: 16px; padding-right: 16px; 
	position: relative; 
	height: auto;
}

.entity-details-page.in-edit .panel{
	margin-left: 8px; margin-right: 8px;	
}

.entity-details-page .panel.in-edit { z-index: 4; padding-top: 16px; padding-bottom: 16px; }

.entity-details-page .panel h5 { line-height: 2.6rem; }


.entity-details-page .btn-edit { position: absolute; right: 16px; }

.entity-details-page .users-grid,
.entity-details-page .entity-alerts { margin-top: 24px }

.entity-details-page .users-grid.in-edit > .users-grid { display: none; }
.presentation-mode-page .topic-preview {
	padding-bottom: 1.5vh;
	text-align: center;
}

.presentation-mode-page .votes-frame-container {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	overflow: auto;
	padding-bottom: 1.5vh;
}

.presentation-mode-page .votes-frame {
	min-width: 30vw;
	margin-left: 3vw;
	margin-right: 3vw;
	margin-bottom: 1.5vh;
	display: inline-grid;
	grid-template-columns: repeat(10, [col-start] 1fr);
	align-self: center;
}

.presentation-mode-page .vote-qr {
	display: inline-block;
	-webkit-align-self: center;
	        align-self: center;
	width: 30vw;
	max-height: 30vw;
}

.presentation-mode-page .vote-qr .qr-preview {
	width: 100%;
	height: 100%;
}

@media (max-width: 769px) {
	.presentation-mode-page .votes-frame-container {
		-webkit-flex-direction: column;
		        flex-direction: column;
		overflow: visible;
	}
	.presentation-mode-page .votes-frame {
		min-width: 35vh;
	}
	.presentation-mode-page .vote-qr {
		max-height: 35vh;
		width: 35vh;
	}
}

.presentation-mode-page .vote-box {
	border: 1px solid #f3f3f3;
}

.presentation-mode-page .votes-text {
	height: 3vh;
	text-align: center;
	padding-bottom: 1.5vh;
	overflow: hidden;
}

.presentation-mode-page .votes-text-entry {
	display: inline-block;
	margin-left: 0.3vw;
	max-width: 15vw;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.presentation-mode-page .vote-location {
	font-style: italic;
}

@-webkit-keyframes act-vote-given {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		visibility: visible;
	}
	1.75% {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
	}
	2.5% {
		opacity: 0.3;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	50% {
		opacity: 0.15;
	}
	100% {
		opacity: 0.15;
		visibility: hidden;
	}
}

@keyframes act-vote-given {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		visibility: visible;
	}
	1.75% {
		-webkit-transform: scale(1.5);
		        transform: scale(1.5);
	}
	2.5% {
		opacity: 0.3;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	50% {
		opacity: 0.15;
	}
	100% {
		opacity: 0.15;
		visibility: hidden;
	}
}

.plans-container {
    display: -webkit-flex;
    display: flex; 
    -webkit-flex-direction: row; 
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 24px auto 24px;
}

.plans-container .plan-container .title-price-overview {
    height: 270px;
    text-align: center;
}

.plans-container .plan-container.free .title-price-overview,
.plans-container .plan-container.enterprise .title-price-overview  { height: 192px; }

.plans-container .plan-container .title-price-overview.no-price { height: 156px; }

.plans-container .plan-container .title-container,
.plans-container .plan-container .mainfeatures-overview {
    padding: 0 16px;
    text-align: left;
}

.plans-container .plan-container .title-price-overview .btn { margin-top: 16px; }

.plans-container .plan-container {
    /* as a child component */
    -webkit-flex: 1 1;
            flex: 1 1;

    /* as a parent component */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; 

    /* general */
    position: relative;

    -webkit-align-self: flex-start;

            align-self: flex-start;
    
    min-width: 280px;
    width: 25%;
    max-width: 350px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.plans-container .plan-container .details {
    background: #fff;
    color: #666;
    text-align: center;
}

.plans-container .plan-container.free,
.plans-container .plan-container.enterprise {
    margin-top: 32px
}

.plans-container .plan-container.pro {
    margin-top: 16px
}

.plans-container .plan-container.free .details {
    background: transparent;
}

.plans-container .plan-container.enterprise .details {
    background: transparent;
    color: #fff;
}

.plans-container .plan-container .title-price-overview .pricetag-number {
    font-size: 4.2rem;
    font-weight: 800;
}

.plans-container .plan-container .title-price-overview .pricetag-currency {
    position: relative;
    font-size: 2.2rem;
    bottom: 15px;
    margin-right: 3px;
}

.plans-container .plan-container .title-price-overview .fadeout {
    color: #fcfcfc79;
}

.plans-container .plan-container .title-price-overview .prices-label {
    display: block;
    margin-top: -4px
}

.plans-container .plan-container .title-price-overview .prices-label > label{
    text-align: right;
    width: 50%;
    padding-right: 12px;
}

.plans-container .plan-container .title-price-overview .prices-label > label:last-child {
    text-align: left;
    padding-left: 22px;
}

.plans-container .slider,
.plans-container .slider:before,
.plans-container input:checked + .slider,
.plans-container input:checked + .slider:before {
    border: 2px solid #ffffff;
}

.plans-container .plan-container ul {
    margin: 8px 12px 8px;
}

.plans-container .plan-container li {
    line-height: auto;
    margin-bottom: 8px;
}

.plans-container .plan-container .features-overview {
    position: relative;
    height: 534px;
    text-align: left;
}

.plans-container .plan-container .mainfeatures-overview {
    border-top: 1px solid #c2c2c2;
    width: 100%;
    margin-top: 16px;
}

.plans-container .plan-container .mainfeatures-overview h5{
    text-transform: none;
    font-size: 1.6rem;  
    color: #949494;
    font-weight: 350;
}

.plan-container.free .title-price-overview .price-container,  
.plan-container.pro .title-price-overview .price-container, 
.plan-container.business .title-price-overview .price-container { 
    text-align: center;
}

/* FREE plans */

/* PRO plans */
.plan-container.pro .features-overview,
.plan-container.pro .mainfeatures-overview {
    border-right: 1px solid #e7e7e7;
}

/* BUSINESS plans */


/* ENTERPRISE plans */
.plan-container.enterprise {
    font-size: 16px;
}

.plan-container.enterprise .features-overview {
    font-weight: 500;
}

.plan-container.enterprise .mainfeatures-overview h5{
    color: #ffffff;   
}

@media (max-width: 1366px) {
    
}

@media (max-width: 769px) {
    .plans-container {
        -webkit-flex-direction: column-reverse;
                flex-direction: column-reverse;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
}
